import React from 'react'
// import more from "../../images/defiwallet/defi-wallet.svg"



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3">What is</span> DeFi wallet Development?</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-lg-8 col-md-12 left-side order2">
              <p className="pharagraph" >DeFi wallets are developed to store cryptocurrencies and digital assets, allowing users to trade or stake them using DeFi protocols. Unlike custodial wallets linked to centralized exchanges, non-custodial crypto wallets offer flexibility and versatility for various DeFi tokens. Thus you have complete ownership of the wallet. DeFi wallets can be used on various websites and mobile apps.
              </p>
            </div>
            <div className="col-lg-4 col-md-12 right-side order1" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/defi-wallet.svg" alt="DeFi wallet" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis