import React from 'react'
// import more from "../../images/defiwallet/why-should-start-defi-wallet.svg"
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="whyshould">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Why should</span> you start
              A DeFi wallet for yourself?
              </h2>
              <p className="pharagraph">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/why-should-start-defi-wallet.svg" alt="Why Should Start DeFi wallet" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph"  >If you have your own non-custodial DeFi crypto wallet you can give your customers complete control over their cryptocurrencies and private keys. </li>
                <li className="pharagraph" >This will instill more confidence in them and they will readily use your interface.
                </li>
                <li className="pharagraph" >Major financial companies all over the world are adopting  DeFi wallets as preferred alternatives for conventional digital wallets.</li>
                <li className="pharagraph" >DeFi stands for decentralized financing. If you have a centralized crypto wallet it is prone to identity theft because the smart contracts can be altered. </li>
                <li className="pharagraph" > As the DeFi wallets are decentralized, it is almost impossible to exploit, or rather find any vulnerabilities. User’s cryptocurrencies and other confidential information are completely secure in the DeFi wallet.</li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould