import React from 'react'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Top Features</span> Of our DeFi wallet Development</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Simple User Interface</h3>
                <p className="pharagraph">Our DeFi wallet provides a simple and neat interface which makes user experience easy and reduces their efforts to access it.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/non-custodial.png" alt="Simple User Interface" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/complete-control-over-digital-assets.png" alt="Multi-cryptocurrency support" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h3 className="heading-h4">Multi-Cryptocurrency support</h3>
                <p className="pharagraph">DeFi wallet supports all chains and networks which enables hassle-free transactions of all cryptos easily.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Duplicate Payments Denial</h3>
                <p className="pharagraph">Our platform automatically detects and rejects duplicate payments, thus effectively eliminating the risk of chargebacks.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/multi-cryptocurrency-support.png" alt="Duplicate Payments Denial" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/simple-user-interface.png" alt="Multi-Signature" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h3 className="heading-h4">Multi-Signature</h3>
                <p className="pharagraph">The multi-signature feature is the most secure and fail-proof way to store cryptocurrency. Our DeFi wallet requires two or more private keys to sign and send a transaction. It requires multiple cryptographic signatures (a private key’s unique fingerprint) to access the wallet.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Complete Control</h3>
                <p className="pharagraph">Our DeFi wallet has a unique keypair that provides full control of your cryptos and keys. For added security, we also provide a recovery phrase in case you forget your password.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/multi-signature.png" alt="Complete Control over Digital Assets" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures