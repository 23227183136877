import React from 'react'
// import more from "../../images/defiwallet/usecase-of-defi-wallet.svg"



class UsecaseOf extends React.Component {


  render() {

    return (
      <section className="usecase">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Use Case</span> of our</span>
                DeFi wallet Development</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/usecase-of-defi-wallet.svg" alt="Use Case of DeFi wallet Development" />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Asset Management  -</b>  The assets, once minted into NFTs, can be easily bought, sold, transferred, or auctioned using DeFi wallets.
              </p>
              <p className="pharagraph" ><b>Compliance -</b> The authentication keys built on smart contracts can be preserved inside DeFi wallets and they can always be used for authentication.
              </p>
              <p className="pharagraph" ><b>Application Development  -</b>  The already authenticated DeFi wallets can be used to develop further tools and blockchain solutions.
              </p>
              <p className="pharagraph" ><b>Digital Identity  -</b>  A DeFi wallet can be used as a portable self-identity tool that is completely secure and private.
              </p>
              <p className="pharagraph" ><b>Peer-To-Peer Borrowing And Lending  -</b>  Finances can be raised from peer-to-peer without getting conventional financial institutions into the picture, by transferring cryptocurrencies from one DeFi wallet to another.
              </p>
              <p className="pharagraph" ><b>Payment Solutions -</b> DeFi wallets can be the safest way to transfer money not just between individuals, but also between continents.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default UsecaseOf