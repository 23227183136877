import React from 'react'


const DevApproach = () => {


  return (
    <div className='pt-100'>
    <section className="devapproach">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of our</span>
              DeFi wallet Development
            </h3>
            <p className="pharagraph head">Coinsclone follows some standard procedures to create and deploy a DeFi wallet Development.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/project-analysis.svg" alt="project analysis" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Requirement Gathering</h5>
            <p  className="pharagraph">In the initial stage, we would gather the requirements from clients and analyze them with the current crypto market. After the research, we will provide the right solution for starting a DeFi wallet business.
            </p>
            <br />
            <h5 >Planning </h5>
            <p  className="pharagraph">Our experts will derive a rough plan for DeFi wallet Development based on the business requirements of clients.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5 >Designing</h5>
            <p  className="pharagraph">Each design that is implemented in the DeFi wallet will be moved to the next stage after confirmation from both the client side and the designer team.
            </p>
            <br />
            <h5 >Development</h5>
            <p  className="pharagraph">In this stage, we would develop and customize the entire crypto exchange as per the client’s needs.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/designing-development.svg" alt="Designing and development" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/testing-deployment.svg" alt="Testing and deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Testing</h5>
            <p  className="pharagraph">After the development stage, our testing team will check the quality of the DeFi wallet Development by conducting various tests. If any functionality doesn’t work properly, then our team will rectify the issue immediately.
            </p>
            <br />
            <h5 >Deployment</h5>
            <p  className="pharagraph">Our development team will deploy the superfine DeFi wallet Development to the server as per the client’s request.
            </p>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default DevApproach