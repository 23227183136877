import * as React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/defiwallet/Banner"
import Whatis from "../components/defiwallet/Whatis"
import WhyShould from "../components/defiwallet/WhyShould"
import TopFeatures from "../components/defiwallet/TopFeatures"
import HowOur from "../components/defiwallet/HowOur"
import CoreFeatures from "../components/defiwallet/CoreFeatures"
import SecurityofOur from "../components/defiwallet/SecurityofOur"
import UsecaseOf from "../components/defiwallet/UsecaseOf"
import WhyChoose from "../components/defiwallet/WhyChoose"
import Testimonial from "../components/whitelabelpage/Testimonial"
import Review from "../components/Landing/Review"
import PortfolioSection from "../components/Landing/Portfolio"
import DevApproach from "../components/defiwallet/DevApproach"
import TechStack from "../components/defiwallet/TechStack"
import FaqSection from "../components/defiwallet/FaqSection"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"

// import Header from "../components/header"




const DeFiwallet = () => (
  <section className="dev-page">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>DeFi Wallet Development Company | DeFi Wallet Development Services</title>                     
        <meta name="description" content="Coinsclone is a DeFi Wallet Development Company offering complete DeFi wallet development services and building a custom DeFi wallet with unique features." />
        <meta name="keywords" content="White Label DeFi Wallet Development Services, DeFi Wallet Development Solutions, Crypto Wallet Development, Blockchain Wallet Development" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="DeFi Wallet Development Company | DeFi Wallet Development Services" />
        <meta property="og:description" content="Coinsclone is a DeFi Wallet Development Company offering complete DeFi wallet development services and building a custom DeFi wallet with unique features." />
        <meta property="og:url" content="https://www.coinsclone.com/defi-wallet-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/defi-wallet-development-company.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a DeFi Wallet Development Company offering complete DeFi wallet development services and building a custom DeFi wallet with unique features." />
        <meta name="twitter:title" content="DeFi Wallet Development Company | DeFi Wallet Development Services" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/defi-wallet-development-company.png" />
        <link rel="canonical" href="https://www.coinsclone.com/defi-wallet-development-company/" />
      </Helmet>
      {/* <Header /> */}
      <BannerSection />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | DeFi Wallet Development Company</span>
        </div>
      </div>
      <Whatis />
      <WhyShould />
      <TopFeatures />
      <HowOur />
      <CoreFeatures />
      <SecurityofOur />
      <UsecaseOf />
      <WhyChoose />
      <Testimonial />
      <Review />
      <PortfolioSection />
      <DevApproach />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default DeFiwallet
