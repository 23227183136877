import React ,{useEffect, useState} from 'react'
import { StaticImage } from 'gatsby-plugin-image'




const HowOur = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <section className="how-our">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h2 className="heading-h2"><span className="heading-h3">How Does our
          </span> DeFi wallet Software Work?
          </h2>
        </div>
        { isDesktop && (
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="997.777" height="977.156" viewBox="0 0 997.777 977.156">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_8917" data-name="Rectangle 8917" width="200.845" height="232.006" fill="none" />
            </clipPath>
            <clipPath id="clip-path-3">
              <rect id="Rectangle_8850" data-name="Rectangle 8850" width="69.345" height="99.167" fill="none" />
            </clipPath>
            <clipPath id="clip-path-4">
              <rect id="Rectangle_8851" data-name="Rectangle 8851" width="57.363" height="107.348" fill="none" />
            </clipPath>
            <clipPath id="clip-path-5">
              <rect id="Rectangle_8852" data-name="Rectangle 8852" width="30.651" height="49.469" fill="none" />
            </clipPath>
            <clipPath id="clip-path-6">
              <rect id="Rectangle_8853" data-name="Rectangle 8853" width="16.32" height="24.046" fill="none" />
            </clipPath>
            <clipPath id="clip-path-7">
              <rect id="Rectangle_8854" data-name="Rectangle 8854" width="57.947" height="42.731" fill="none" />
            </clipPath>
            <clipPath id="clip-path-8">
              <rect id="Rectangle_8879" data-name="Rectangle 8879" width="36.84" height="19.861" fill="none" />
            </clipPath>
            <clipPath id="clip-path-9">
              <rect id="Rectangle_8856" data-name="Rectangle 8856" width="15.435" height="7.967" fill="none" />
            </clipPath>
            <clipPath id="clip-path-10">
              <rect id="Rectangle_8857" data-name="Rectangle 8857" width="15.435" height="7.965" fill="none" />
            </clipPath>
            <clipPath id="clip-path-12">
              <rect id="Rectangle_8859" data-name="Rectangle 8859" width="15.435" height="7.966" fill="none" />
            </clipPath>
            <clipPath id="clip-path-13">
              <rect id="Rectangle_8860" data-name="Rectangle 8860" width="11.469" height="5.973" fill="none" />
            </clipPath>
            <clipPath id="clip-path-14">
              <rect id="Rectangle_8861" data-name="Rectangle 8861" width="15.434" height="7.966" fill="none" />
            </clipPath>
            <clipPath id="clip-path-19">
              <rect id="Rectangle_8866" data-name="Rectangle 8866" width="15.435" height="7.968" fill="none" />
            </clipPath>
            <clipPath id="clip-path-20">
              <rect id="Rectangle_8867" data-name="Rectangle 8867" width="15.434" height="7.967" fill="none" />
            </clipPath>
            <clipPath id="clip-path-22">
              <rect id="Rectangle_8869" data-name="Rectangle 8869" width="13.488" height="6.97" fill="none" />
            </clipPath>
            <clipPath id="clip-path-23">
              <rect id="Rectangle_8870" data-name="Rectangle 8870" width="11.117" height="5.77" fill="none" />
            </clipPath>
            <clipPath id="clip-path-25">
              <rect id="Rectangle_8872" data-name="Rectangle 8872" width="15.434" height="7.968" fill="none" />
            </clipPath>
            <clipPath id="clip-path-27">
              <rect id="Rectangle_8874" data-name="Rectangle 8874" width="15.433" height="7.967" fill="none" />
            </clipPath>
            <clipPath id="clip-path-28">
              <rect id="Rectangle_8875" data-name="Rectangle 8875" width="10.635" height="5.566" fill="none" />
            </clipPath>
            <clipPath id="clip-path-29">
              <rect id="Rectangle_8876" data-name="Rectangle 8876" width="11.794" height="6.16" fill="none" />
            </clipPath>
            <clipPath id="clip-path-31">
              <rect id="Rectangle_8878" data-name="Rectangle 8878" width="12.701" height="6.578" fill="none" />
            </clipPath>
            <clipPath id="clip-path-32">
              <rect id="Rectangle_8903" data-name="Rectangle 8903" width="34.268" height="22.103" fill="none" />
            </clipPath>
            <clipPath id="clip-path-33">
              <rect id="Rectangle_8880" data-name="Rectangle 8880" width="9.602" height="6.37" fill="none" />
            </clipPath>
            <clipPath id="clip-path-34">
              <rect id="Rectangle_8881" data-name="Rectangle 8881" width="12.263" height="8.084" fill="none" />
            </clipPath>
            <clipPath id="clip-path-35">
              <rect id="Rectangle_8882" data-name="Rectangle 8882" width="14.692" height="9.663" fill="none" />
            </clipPath>
            <clipPath id="clip-path-36">
              <rect id="Rectangle_8883" data-name="Rectangle 8883" width="14.691" height="9.663" fill="none" />
            </clipPath>
            <clipPath id="clip-path-39">
              <rect id="Rectangle_8886" data-name="Rectangle 8886" width="14.692" height="9.664" fill="none" />
            </clipPath>
            <clipPath id="clip-path-40">
              <rect id="Rectangle_8887" data-name="Rectangle 8887" width="9.429" height="6.22" fill="none" />
            </clipPath>
            <clipPath id="clip-path-42">
              <rect id="Rectangle_8889" data-name="Rectangle 8889" width="14.691" height="9.664" fill="none" />
            </clipPath>
            <clipPath id="clip-path-48">
              <rect id="Rectangle_8895" data-name="Rectangle 8895" width="6.241" height="4.158" fill="none" />
            </clipPath>
            <clipPath id="clip-path-49">
              <rect id="Rectangle_8896" data-name="Rectangle 8896" width="8.94" height="5.905" fill="none" />
            </clipPath>
            <clipPath id="clip-path-54">
              <rect id="Rectangle_8901" data-name="Rectangle 8901" width="7.989" height="5.29" fill="none" />
            </clipPath>
            <clipPath id="clip-path-56">
              <rect id="Rectangle_8904" data-name="Rectangle 8904" width="30.829" height="15.269" fill="none" />
            </clipPath>
            <clipPath id="clip-path-57">
              <rect id="Rectangle_8907" data-name="Rectangle 8907" width="0.533" height="0.326" fill="none" />
            </clipPath>
            <clipPath id="clip-path-62">
              <rect id="Rectangle_8912" data-name="Rectangle 8912" width="0.4" height="0.231" fill="none" />
            </clipPath>
            <clipPath id="clip-path-64">
              <rect id="Rectangle_8848" data-name="Rectangle 8848" width="57.142" height="155.281" fill="none" />
            </clipPath>
            <clipPath id="clip-path-66">
              <rect id="Rectangle_8842" data-name="Rectangle 8842" width="6.525" height="47.288" fill="none" />
            </clipPath>
            <clipPath id="clip-path-67">
              <rect id="Rectangle_8843" data-name="Rectangle 8843" width="4.82" height="33.838" fill="none" />
            </clipPath>
            <clipPath id="clip-path-68">
              <rect id="Rectangle_8844" data-name="Rectangle 8844" width="6.027" height="35.943" fill="none" />
            </clipPath>
            <clipPath id="clip-path-69">
              <rect id="Rectangle_8845" data-name="Rectangle 8845" width="4.621" height="43.822" fill="none" />
            </clipPath>
            <clipPath id="clip-path-70">
              <rect id="Rectangle_8846" data-name="Rectangle 8846" width="4.872" height="21.945" fill="none" />
            </clipPath>
            <clipPath id="clip-path-71">
              <rect id="Rectangle_8841" data-name="Rectangle 8841" width="175.324" height="237.915" fill="none" />
            </clipPath>
            <clipPath id="clip-path-73">
              <rect id="Rectangle_8831" data-name="Rectangle 8831" width="45.074" height="36.466" fill="none" />
            </clipPath>
            <clipPath id="clip-path-74">
              <rect id="Rectangle_8832" data-name="Rectangle 8832" width="62.008" height="35.801" fill="none" />
            </clipPath>
            <clipPath id="clip-path-75">
              <rect id="Rectangle_8833" data-name="Rectangle 8833" width="86.897" height="85.131" fill="none" />
            </clipPath>
            <clipPath id="clip-path-76">
              <rect id="Rectangle_8834" data-name="Rectangle 8834" width="29.997" height="49.01" fill="none" />
            </clipPath>
            <clipPath id="clip-path-77">
              <rect id="Rectangle_8835" data-name="Rectangle 8835" width="29.655" height="53.275" fill="none" />
            </clipPath>
            <clipPath id="clip-path-78">
              <rect id="Rectangle_8836" data-name="Rectangle 8836" width="107.855" height="95.058" fill="none" />
            </clipPath>
            <clipPath id="clip-path-79">
              <rect id="Rectangle_8837" data-name="Rectangle 8837" width="58.507" height="57.187" fill="none" />
            </clipPath>
            <clipPath id="clip-path-80">
              <rect id="Rectangle_8838" data-name="Rectangle 8838" width="18.094" height="19.612" fill="none" />
            </clipPath>
          </defs>
          <g id="Flow" transform="translate(-427.417 -4958.673)">
            <g id="Ellipse_1376" data-name="Ellipse 1376" transform="translate(477 4984.096)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
              <circle cx={108} cy={108} r={108} stroke="none" />
              <circle cx={108} cy={108} r={107} fill="none" />
            </g>
            <g id="Ellipse_196" data-name="Ellipse 196" transform="translate(1187 5114.096)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
              <circle cx={108} cy={108} r={108} stroke="none" />
              <circle cx={108} cy={108} r={107} fill="none" />
            </g>
            <g id="Admin" transform="translate(1137.656 5092.096)">
              <g id="Group_28595" data-name="Group 28595" clipPath="url(#clip-path)">
                <path id="Path_111" data-name="Path 111" d="M42.258,113.342l1.767-3.063L19.874,96.334l-1.221,3.379Z" transform="translate(127.876 53.141)" fill="#7d4b34" />
                <rect id="Rectangle_8849" data-name="Rectangle 8849" width="3.537" height="29.783" transform="translate(171.548 167.633) rotate(-119.999)" fill="#7d4b34" />
                <path id="Path_112" data-name="Path 112" d="M20.223,104.308l22.932-13.24-1.769-3.063L18.653,101.132Z" transform="translate(128.748 48.546)" fill="#7d4b34" />
                <path id="Path_113" data-name="Path 113" d="M28.738,105.219l.7-3.679L4.713,87.265,2.946,90.328Z" transform="translate(166.834 48.138)" fill="#7d4b34" />
                <g id="Group_28594" data-name="Group 28594">
                  <g id="Group_28593" data-name="Group 28593" clipPath="url(#clip-path)">
                    <path id="Path_114" data-name="Path 114" d="M19.686,117.012c-1.441,0-2.61.8-2.61,1.791s1.168,1.789,2.61,1.789,2.61-.8,2.61-1.789-1.168-1.791-2.61-1.791" transform="translate(152.053 64.547)" fill="#7d4b34" />
                    <path id="Path_115" data-name="Path 115" d="M19.686,100.179c-1.441,0-2.61.8-2.61,1.789s1.168,1.791,2.61,1.791,2.61-.8,2.61-1.791-1.168-1.789-2.61-1.789" transform="translate(152.053 55.262)" fill="#e3d1b3" />
                    <path id="Path_116" data-name="Path 116" d="M38.9,107.522c0-.988-1.17-1.791-2.611-1.791s-2.61.8-2.61,1.791,1.168,1.789,2.61,1.789,2.611-.8,2.611-1.789" transform="translate(109.677 58.324)" fill="#b58b69" />
                    <path id="Path_117" data-name="Path 117" d="M5.221,109.83c0-.988-1.168-1.791-2.61-1.791S0,108.841,0,109.83s1.168,1.791,2.611,1.791,2.61-.8,2.61-1.791" transform="translate(195.623 59.598)" fill="#7d4b34" />
                    <path id="Path_118" data-name="Path 118" d="M19.966,78.336c-1.876,0-3.4,1.043-3.4,2.329s1.522,2.329,3.4,2.329,3.4-1.043,3.4-2.329-1.522-2.329-3.4-2.329" transform="translate(151.773 43.212)" fill="#e3d1b3" />
                    <path id="Path_119" data-name="Path 119" d="M32.78,74.208c0-1.286-1.521-2.329-3.4-2.329s-3.4,1.043-3.4,2.329,1.522,2.331,3.4,2.331,3.4-1.043,3.4-2.331" transform="translate(127.746 39.651)" fill="#e3d1b3" />
                    <path id="Path_120" data-name="Path 120" d="M13.947,74.208c0-1.286-1.522-2.329-3.4-2.329s-3.4,1.043-3.4,2.329,1.521,2.331,3.4,2.331,3.4-1.043,3.4-2.331" transform="translate(175.801 39.651)" fill="#e3d1b3" />
                    <path id="Path_121" data-name="Path 121" d="M12.671,128.948h5.221L6.795,73.38H0Z" transform="translate(182.952 40.478)" fill="#7d4b34" />
                    <path id="Path_122" data-name="Path 122" d="M31.206,125.368H25.985L36.356,73.379h6.795Z" transform="translate(117.374 40.478)" fill="#7d4b34" />
                    <path id="Path_123" data-name="Path 123" d="M17.417,139.309h5.1l.849-59.472H16.569Z" transform="translate(151.773 44.04)" fill="#7d4b34" />
                    <path id="Path_124" data-name="Path 124" d="M9.9,70.555C.064,76.234.064,85.443,9.9,91.121s25.787,5.681,35.622,0,9.839-14.888,0-20.567-25.787-5.681-35.622,0" transform="translate(144.027 36.57)" fill="#1d1d1b" />
                    <path id="Path_125" data-name="Path 125" d="M9.9,67.82C.064,73.5.064,82.708,9.9,88.387s25.787,5.681,35.622,0,9.839-14.888,0-20.567-25.787-5.679-35.622,0" transform="translate(144.027 35.062)" fill="#b58b69" />
                    <path id="Path_126" data-name="Path 126" d="M77.957,115.432c2.23,2.234,2.556,6.588,1.817,10.865-1.666,2.487-2.591,3.687-5.428,4.523a42.7,42.7,0,0,0-6.743,3.127c-6.5,6.742-12.506,7.558-14.722,6.736-7.484-2.776.54-9.189,2.714-10.767.632-.458,8.965-8.388,8.965-8.388l1.972-3.125-.234-6.824,10.843-3.209Z" transform="translate(43.514 59.78)" fill="#22709c" />
                    <path id="Path_127" data-name="Path 127" d="M13.61,96.893c1.431,16.953,6.74,29.714,10.934,52.49,1.983,1.578,6.059,3.019,9.119,1.992a5.31,5.31,0,0,0,3.552-4.085c.661-19.42.493-34.449-4.864-47,16.3-7.175,37.864-9.445,51.167-20.576,5.172-4.47,9.08-8.576,10.294-12.632a8.362,8.362,0,0,0-.317-6.039,12.492,12.492,0,0,0-2.071-3.187l-4.244-3.03L68.872,52.209c-2.329,1.234-4.925,2.655-7.668,4.224C41.429,67.711,13.51,86.422,13.61,96.893" transform="translate(85.501 28.8)" fill="#3c3c3b" />
                    <g id="Group_28412" data-name="Group 28412" transform="translate(109.965 81.009)" opacity="0.5" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                      <g id="Group_28411" data-name="Group 28411" transform="translate(0)">
                        <g id="Group_28410" data-name="Group 28410" clipPath="url(#clip-path-3)">
                          <path id="Path_128" data-name="Path 128" d="M21.773,150.942s.5.178,1.3.434a5.314,5.314,0,0,0,3.553-4.085c.661-19.422.493-34.451-4.864-47,16.3-7.175,37.864-9.443,51.167-20.576,5.172-4.47,9.079-8.574,10.294-12.63l-.317-6.04a12.519,12.519,0,0,0-2.071-3.187l-4.244-3.029L58.286,52.209c-2.329,1.234-4.925,2.655-7.668,4.224C49.227,73.663,32.46,85.8,13.878,97.327c6.987,16.066,8.979,34.2,7.895,53.615" transform="translate(-13.878 -52.209)" fill="#3c3c3b" />
                        </g>
                      </g>
                    </g>
                    <path id="Path_129" data-name="Path 129" d="M60.46,126.191c2.23,2.234,2.286,6.324,1.817,10.865a11.989,11.989,0,0,1-6.908,4.77s-10.363,9.868-20.23,8.388,0-8.551,0-8.551l11.375-9.87,2.194-3.043.095-6.413,10.843-3.209Z" transform="translate(90.616 65.715)" fill="#22709c" />
                    <path id="Path_130" data-name="Path 130" d="M4.015,106.883c1.431,16.953,11.1,33.473,15.3,56.25,3.819,2.911,7.184,3.424,9.879.355a13.243,13.243,0,0,0,2.062-3.316c.67-19.411-.661-36.542-6.02-49.1,16.3-7.175,29.191-14.28,42.493-25.411,9.069-7.836,8.506-21.226,2.161-28.539l-1.815-.276-.346-.05L37.805,63c-1.629,1.856-3.286,3.73-4.945,5.606-2.22,2.5-4.45,5.013-6.632,7.49C15.285,88.518,5.466,100.251,4.015,106.883" transform="translate(120.013 31.333)" fill="#3c3c3b" />
                    <g id="Group_28415" data-name="Group 28415" transform="translate(137.251 88.183)" opacity="0.5" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                      <g id="Group_28414" data-name="Group 28414" transform="translate(0)">
                        <g id="Group_28413" data-name="Group 28413" clipPath="url(#clip-path-4)">
                          <path id="Path_131" data-name="Path 131" d="M14.062,164.181l1.9-.711a13.2,13.2,0,0,0,2.064-3.316c.67-19.411-.663-36.542-6.02-49.1C28.312,103.886,41.2,96.78,54.5,85.648c9.069-7.836,8.506-21.226,2.161-28.539l-1.815-.276L19.637,68.587c-2.22,2.5-4.45,5.013-6.632,7.49,3.829.593,12.238.79,29.743.582-6.236,13.164-20.862,24.356-38.733,32.083,8.241,14.081,10.273,33.739,10.047,55.44" transform="translate(-4.016 -56.832)" fill="#3c3c3b" />
                        </g>
                      </g>
                    </g>
                    <path id="Path_132" data-name="Path 132" d="M32.182,79.373c2.408-1.145,4.085-.138,5.181,2.625.344-.188.681-.385,1.016-.583,9.1-5.2,17.526-12,26.043-20.022l4.608-16,5.753-19.943,3.277-6.068-5.744-4.363C63.781,13.749,61,17.726,59.083,27.012L51.258,54.979c-6.543,5.527-12.9,13.963-19.076,24.395" transform="translate(72.849 8.159)" fill="#308dc2" />
                    <g id="Group_28418" data-name="Group 28418" transform="translate(111.229 40.105)" opacity="0.5" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                      <g id="Group_28417" data-name="Group 28417">
                        <g id="Group_28416" data-name="Group 28416" clipPath="url(#clip-path-5)">
                          <path id="Path_133" data-name="Path 133" d="M38,75.317c9.1-5.2,17.526-12,26.043-20.022l4.608-16L61.785,25.846C60.1,37.038,55.449,44.9,55.014,54.958Z" transform="translate(-38.002 -25.848)" fill="#3f76ab" />
                        </g>
                      </g>
                    </g>
                    <path id="Path_134" data-name="Path 134" d="M18.629,6.962C35.6,12.478,31.979,28.5,27.4,41.05L15.42,29.783Z" transform="translate(145.703 3.84)" fill="#1d1d1b" />
                    <path id="Path_135" data-name="Path 135" d="M7.056,55.409a1.5,1.5,0,0,0,.02.306c.414,9.76,13.707,8.852,13.707,8.852l5.378,11.674C27.524,93.185,56.8,97.458,65.024,79.7,59.1,71.307,59.1,71.307,58.609,65.544q.7-2.309,1.2-4.481c2.653-11.367,1.4-19.993-3.5-29.723C51.5,25.114,40.717,18.876,33.918,16.35l-19.884-.464L10.076,30.315c1.066,18.1-3.137,17.476-3.019,25.095" transform="translate(124.876 8.763)" fill="#308dc2" />
                    <path id="Path_136" data-name="Path 136" d="M35.192,14.013c1.077,3.707,3.977,9.439,4.683,12.973.81,4.357-7.227,11.134-12.609,10.743-3.809-.749-4.781-5.64-5.341-10.14Z" transform="translate(126.893 7.73)" fill="#ffaa6d" />
                    <g id="Group_28421" data-name="Group 28421" transform="translate(143.359 24.572)" opacity="0.4" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                      <g id="Group_28420" data-name="Group 28420" transform="translate(0)">
                        <g id="Group_28419" data-name="Group 28419" clipPath="url(#clip-path-6)">
                          <path id="Path_137" data-name="Path 137" d="M42.492,15.836s3.17,26.07-9.359,23.92c-8.171-1.4-6.452-14.882-6.452-14.882Z" transform="translate(-26.53 -15.836)" fill="#ffaa6d" />
                        </g>
                      </g>
                    </g>
                    <path id="Path_138" data-name="Path 138" d="M37.82.515c-12.554,1.736-16.433,9.1-15.178,19.1.149,8.38,7.116,19.473,11.986,19.265,6.942.383,10.356-7.718,16.286-16.384C55.5,8.235,49.506-1.1,37.82.515" transform="translate(113.604 0.184)" fill="#ffaa6d" />
                    <path id="Path_139" data-name="Path 139" d="M22.9,20.075c2.61,2.793,6.793,4.376,12,5.575,4.4,1.817,8.669,1.639,10.371,4.1l5.1-2.112c5.123-5.9,5.631-22.758-3.156-26.322C33.114-4.405,16.349,9.758,22.9,20.075" transform="translate(113.513 0)" fill="#1d1d1b" />
                    <path id="Path_140" data-name="Path 140" d="M23.718,18.921c.774-3.075,2.914-5.089,4.739-3.319.847.824.793,2.338,0,4.262a3.446,3.446,0,0,1-2.74,2.376c-1.725.264-2.593-.951-2-3.319" transform="translate(135.26 8.242)" fill="#ffaa6d" />
                    <g id="Group_28424" data-name="Group 28424" transform="translate(131.952 57.176)" opacity="0.5" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                      <g id="Group_28423" data-name="Group 28423" transform="translate(0)">
                        <g id="Group_28422" data-name="Group 28422" clipPath="url(#clip-path-7)">
                          <path id="Path_141" data-name="Path 141" d="M7.054,44.152C7.468,53.912,20.761,53,20.761,53l5.378,11.674C27.5,81.622,56.781,85.9,65,68.132c-5.921-8.388-5.921-8.388-6.414-14.151.464-1.541.867-3.03,1.2-4.481L47.978,36.85,41.159,52.185C35.632,64.106,22.646,59,21.58,49.4c-1.895,3.038-11.792,2.318-14.526-5.251" transform="translate(-7.054 -36.848)" fill="#3f76ab" />
                        </g>
                      </g>
                    </g>
                    <path id="Path_142" data-name="Path 142" d="M113,131.242c12.415,7.167,12.415,18.789,0,25.957s-32.542,7.167-44.957,0-12.415-18.79,0-25.957,32.542-7.169,44.957,0" transform="translate(-12.595 69.431)" fill="#1d1d1b" />
                    <path id="Path_143" data-name="Path 143" d="M113,129.178c12.415,7.167,12.415,18.789,0,25.957s-32.542,7.167-44.957,0-12.415-18.79,0-25.957,32.542-7.169,44.957,0" transform="translate(-12.595 68.293)" fill="#b58b69" />
                    <path id="Path_144" data-name="Path 144" d="M92.09,132.941c4.731,2.731,4.731,7.158,0,9.89s-12.4,2.729-17.128,0-4.731-7.159,0-9.89,12.4-2.731,17.128,0" transform="translate(-5.6 72.205)" fill="#1d1d1b" />
                    <path id="Path_145" data-name="Path 145" d="M92.09,130.961c4.731,2.731,4.731,7.159,0,9.889s-12.4,2.731-17.128,0-4.731-7.158,0-9.889,12.4-2.731,17.128,0" transform="translate(-5.6 71.112)" fill="#b58b69" />
                    <path id="Path_146" data-name="Path 146" d="M89.106,130.048c3.634,2.1,3.634,5.5,0,7.6a14.532,14.532,0,0,1-13.158,0c-3.634-2.1-3.634-5.5,0-7.6a14.541,14.541,0,0,1,13.158,0" transform="translate(-4.601 70.871)" fill="#1d1d1b" />
                    <rect id="Rectangle_8855" data-name="Rectangle 8855" width="18.609" height="61.14" transform="translate(68.621 144.005)" fill="#1d1d1b" />
                    <path id="Path_147" data-name="Path 147" d="M162.025,72.993c30.432,17.569,30.432,46.057,0,63.626s-79.771,17.571-110.2,0-30.432-46.057,0-63.626,79.771-17.571,110.2,0" transform="translate(-28.999 32.996)" fill="#1d1d1b" />
                    <path id="Path_148" data-name="Path 148" d="M162.025,70.11c30.432,17.572,30.432,46.059,0,63.628s-79.771,17.571-110.2,0-30.432-46.055,0-63.628,79.771-17.568,110.2,0" transform="translate(-28.999 31.407)" fill="#d7e0d7" />
                    <path id="Path_149" data-name="Path 149" d="M162.025,70.11c30.432,17.572,30.432,46.059,0,63.628s-79.771,17.571-110.2,0-30.432-46.055,0-63.628,79.771-17.568,110.2,0" transform="translate(-28.999 31.407)" fill="#b58b69" />
                    <path id="Path_150" data-name="Path 150" d="M61.587,106.649a6.808,6.808,0,0,0,5.068,2.926L94.117,93.719l-5.068-2.926Z" transform="translate(11.167 50.084)" fill="#3c3c3b" />
                    <path id="Path_151" data-name="Path 151" d="M49.927,108.243l18.874,10.9,27.462-15.856v-.608L77.389,92.387,49.927,107.635Z" transform="translate(27.114 50.964)" fill="#3c3c3b" />
                    <path id="Path_152" data-name="Path 152" d="M64.349,99.917l18.874,10.9v-.608l27.462-15.248L91.811,84.06,64.349,99.308Z" transform="translate(-9.687 46.37)" fill="#3c3c3b" />
                    <path id="Path_153" data-name="Path 153" d="M61.587,106.257l5.068,2.926L94.117,93.327,89.049,90.4Z" transform="translate(11.167 49.868)" fill="#3c3c3b" />
                    <path id="Path_154" data-name="Path 154" d="M49.927,107.851l18.874,10.9,27.462-15.855L77.389,92Z" transform="translate(27.114 50.747)" fill="#963035" />
                    <path id="Path_155" data-name="Path 155" d="M64.349,99.524l18.874,10.9,27.462-15.856-18.874-10.9Z" transform="translate(-9.687 46.154)" fill="#963035" />
                    <path id="Path_156" data-name="Path 156" d="M63.975,97.951l25.459-14.7,18.879,12.714-25.459,14.7Z" transform="translate(-6.733 45.925)" fill="#fff" />
                    <path id="Path_157" data-name="Path 157" d="M64.918,83.252l16.16,8.155A5.621,5.621,0,0,1,83.8,95.933c0,.033-19.822-11.411-19.822-11.411Z" transform="translate(17.782 45.924)" fill="#fff" />
                    <path id="Path_158" data-name="Path 158" d="M70.079,101.193l-16.16-10.5a1.887,1.887,0,0,0-1.966-.272,1.8,1.8,0,0,0-.753,1.66c0,.031,19.822,11.474,19.822,11.474Z" transform="translate(50.379 49.782)" fill="#f6f6f6" />
                    <path id="Path_159" data-name="Path 159" d="M51.808,105.054l25.461-14.7L95.4,101.132l-25.459,14.7Z" transform="translate(25.062 49.843)" fill="#f6f6f6" />
                    <path id="Path_160" data-name="Path 160" d="M76.659,97.3l.943,2.358-25.461,14.7L51.2,112Z" transform="translate(43.798 53.674)" fill="#b7bfb7" />
                    <path id="Path_161" data-name="Path 161" d="M81.326,92.726l16.16,8.155a5.621,5.621,0,0,1,2.718,4.526C100.205,105.44,80.383,94,80.383,94Z" transform="translate(-24.085 51.15)" fill="#cbd4cb" />
                    <path id="Path_162" data-name="Path 162" d="M86.487,110.666l-16.16-10.5a1.89,1.89,0,0,0-1.967-.273,1.806,1.806,0,0,0-.751,1.662c0,.031,19.822,11.474,19.822,11.474Z" transform="translate(8.512 55.007)" fill="#cbd4cb" />
                    <g id="Group_28496" data-name="Group 28496" transform="translate(60.6 131.49)" opacity="0.75">
                      <g id="Group_28495" data-name="Group 28495" transform="translate(0)">
                        <g id="Group_28494" data-name="Group 28494" clipPath="url(#clip-path-8)">
                          <g id="Group_28427" data-name="Group 28427" transform="translate(0 11.894)">
                            <g id="Group_28426" data-name="Group 28426">
                              <g id="Group_28425" data-name="Group 28425" clipPath="url(#clip-path-9)">
                                <path id="Path_163" data-name="Path 163" d="M95.709,100.375c-.073-.017-15.183-7.657-15.183-7.657a.164.164,0,0,1,.149-.292l15.107,7.64a.163.163,0,0,1-.073.309" transform="translate(-80.437 -92.408)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28430" data-name="Group 28430" transform="translate(0.973 11.328)">
                            <g id="Group_28429" data-name="Group 28429" transform="translate(0)">
                              <g id="Group_28428" data-name="Group 28428" clipPath="url(#clip-path-10)">
                                <path id="Path_164" data-name="Path 164" d="M95.083,100.01C95.01,99.993,79.9,92.352,79.9,92.352a.161.161,0,0,1-.071-.219.165.165,0,0,1,.22-.071L95.156,99.7a.163.163,0,0,1-.073.309" transform="translate(-79.812 -92.044)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28433" data-name="Group 28433" transform="translate(1.946 10.761)">
                            <g id="Group_28432" data-name="Group 28432" transform="translate(0)">
                              <g id="Group_28431" data-name="Group 28431" clipPath="url(#clip-path-9)">
                                <path id="Path_165" data-name="Path 165" d="M94.456,99.645c-.073-.017-15.183-7.657-15.183-7.657a.164.164,0,0,1,.149-.292l15.107,7.64a.163.163,0,0,1-.073.309" transform="translate(-79.184 -91.678)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28436" data-name="Group 28436" transform="translate(2.919 10.195)">
                            <g id="Group_28435" data-name="Group 28435" transform="translate(0)">
                              <g id="Group_28434" data-name="Group 28434" clipPath="url(#clip-path-12)">
                                <path id="Path_166" data-name="Path 166" d="M93.829,99.28c-.073-.017-15.183-7.657-15.183-7.657a.164.164,0,0,1-.071-.22.167.167,0,0,1,.22-.071L93.9,98.971a.163.163,0,0,1-.073.309" transform="translate(-78.557 -91.314)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28439" data-name="Group 28439" transform="translate(7.857 11.622)">
                            <g id="Group_28438" data-name="Group 28438" transform="translate(0)">
                              <g id="Group_28437" data-name="Group 28437" transform="translate(0)" clipPath="url(#clip-path-13)">
                                <path id="Path_167" data-name="Path 167" d="M89.235,98.206c-.073-.019-11.217-5.663-11.217-5.663a.165.165,0,0,1-.071-.22.163.163,0,0,1,.219-.071L89.308,97.9a.163.163,0,0,1-.073.309" transform="translate(-77.93 -92.234)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28442" data-name="Group 28442" transform="translate(4.865 9.062)">
                            <g id="Group_28441" data-name="Group 28441" transform="translate(0)">
                              <g id="Group_28440" data-name="Group 28440" clipPath="url(#clip-path-14)">
                                <path id="Path_168" data-name="Path 168" d="M92.574,98.55c-.073-.017-15.181-7.657-15.181-7.657a.162.162,0,0,1-.071-.22.165.165,0,0,1,.219-.071l15.107,7.64a.163.163,0,0,1,.073.219.161.161,0,0,1-.146.09" transform="translate(-77.303 -90.584)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28445" data-name="Group 28445" transform="translate(5.838 8.496)">
                            <g id="Group_28444" data-name="Group 28444" transform="translate(0)">
                              <g id="Group_28443" data-name="Group 28443" clipPath="url(#clip-path-12)">
                                <path id="Path_169" data-name="Path 169" d="M91.947,98.185c-.073-.017-15.183-7.657-15.183-7.657a.164.164,0,0,1,.149-.292l15.107,7.64a.163.163,0,0,1-.073.309" transform="translate(-76.676 -90.219)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28448" data-name="Group 28448" transform="translate(6.811 7.93)">
                            <g id="Group_28447" data-name="Group 28447" transform="translate(0)">
                              <g id="Group_28446" data-name="Group 28446" clipPath="url(#clip-path-14)">
                                <path id="Path_170" data-name="Path 170" d="M91.32,97.82c-.073-.017-15.183-7.657-15.183-7.657a.164.164,0,0,1,.149-.292l15.107,7.64a.163.163,0,0,1-.073.309" transform="translate(-76.049 -89.854)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28451" data-name="Group 28451" transform="translate(7.784 7.363)">
                            <g id="Group_28450" data-name="Group 28450" transform="translate(0)">
                              <g id="Group_28449" data-name="Group 28449" clipPath="url(#clip-path-12)">
                                <path id="Path_171" data-name="Path 171" d="M90.693,97.455C90.62,97.438,75.512,89.8,75.512,89.8a.163.163,0,1,1,.147-.29l15.107,7.639a.163.163,0,0,1-.073.309" transform="translate(-75.422 -89.488)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28454" data-name="Group 28454" transform="translate(8.757 6.796)">
                            <g id="Group_28453" data-name="Group 28453" transform="translate(0)">
                              <g id="Group_28452" data-name="Group 28452" clipPath="url(#clip-path-14)">
                                <path id="Path_172" data-name="Path 172" d="M90.066,97.09c-.073-.017-15.183-7.657-15.183-7.657a.164.164,0,0,1,.149-.292l15.107,7.64a.163.163,0,0,1-.073.309" transform="translate(-74.796 -89.123)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28457" data-name="Group 28457" transform="translate(9.729 6.229)">
                            <g id="Group_28456" data-name="Group 28456" transform="translate(0)">
                              <g id="Group_28455" data-name="Group 28455" clipPath="url(#clip-path-19)">
                                <path id="Path_173" data-name="Path 173" d="M89.439,96.726c-.073-.017-15.181-7.657-15.181-7.657a.163.163,0,0,1-.073-.22.16.16,0,0,1,.219-.071l15.108,7.64a.163.163,0,0,1-.073.309" transform="translate(-74.169 -88.758)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28460" data-name="Group 28460" transform="translate(10.703 5.663)">
                            <g id="Group_28459" data-name="Group 28459" transform="translate(0)">
                              <g id="Group_28458" data-name="Group 28458" clipPath="url(#clip-path-20)">
                                <path id="Path_174" data-name="Path 174" d="M88.811,96.36C88.738,96.343,73.63,88.7,73.63,88.7a.162.162,0,0,1-.071-.219.159.159,0,0,1,.219-.071l15.107,7.639a.163.163,0,0,1-.073.309" transform="translate(-73.541 -88.393)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28463" data-name="Group 28463" transform="translate(11.676 5.097)">
                            <g id="Group_28462" data-name="Group 28462" transform="translate(0)">
                              <g id="Group_28461" data-name="Group 28461" clipPath="url(#clip-path-20)">
                                <path id="Path_175" data-name="Path 175" d="M88.181,96C88.11,95.978,73,88.338,73,88.338a.163.163,0,0,1-.071-.219.16.16,0,0,1,.219-.073l15.107,7.64a.163.163,0,0,1,.073.219.165.165,0,0,1-.147.09" transform="translate(-72.912 -88.028)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28466" data-name="Group 28466" transform="translate(14.595 5.527)">
                            <g id="Group_28465" data-name="Group 28465" transform="translate(0)">
                              <g id="Group_28464" data-name="Group 28464" clipPath="url(#clip-path-22)">
                                <path id="Path_176" data-name="Path 176" d="M85.609,95.276c-.071-.017-13.235-6.661-13.235-6.661a.163.163,0,0,1-.07-.219.159.159,0,0,1,.219-.071l13.161,6.643a.163.163,0,0,1-.074.309" transform="translate(-72.285 -88.305)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28469" data-name="Group 28469" transform="translate(17.939 6.162)">
                            <g id="Group_28468" data-name="Group 28468" transform="translate(0)">
                              <g id="Group_28467" data-name="Group 28467" clipPath="url(#clip-path-23)">
                                <path id="Path_177" data-name="Path 177" d="M82.613,94.484c-.073-.019-10.865-5.46-10.865-5.46a.163.163,0,0,1,.147-.292l10.792,5.443a.163.163,0,0,1-.074.309" transform="translate(-71.66 -88.714)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28472" data-name="Group 28472" transform="translate(14.595 3.398)">
                            <g id="Group_28471" data-name="Group 28471" transform="translate(0)">
                              <g id="Group_28470" data-name="Group 28470" clipPath="url(#clip-path-14)">
                                <path id="Path_178" data-name="Path 178" d="M86.3,94.9c-.073-.017-15.181-7.657-15.181-7.657a.162.162,0,0,1-.071-.219.161.161,0,0,1,.219-.071l15.107,7.639a.162.162,0,0,1,.073.219.164.164,0,0,1-.146.09" transform="translate(-71.031 -86.933)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28475" data-name="Group 28475" transform="translate(15.568 2.831)">
                            <g id="Group_28474" data-name="Group 28474" transform="translate(0)">
                              <g id="Group_28473" data-name="Group 28473" clipPath="url(#clip-path-25)">
                                <path id="Path_179" data-name="Path 179" d="M85.675,94.536c-.073-.017-15.181-7.657-15.181-7.657a.164.164,0,0,1-.071-.22.159.159,0,0,1,.219-.071l15.107,7.64a.163.163,0,0,1-.073.309" transform="translate(-70.404 -86.568)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28478" data-name="Group 28478" transform="translate(16.541 2.265)">
                            <g id="Group_28477" data-name="Group 28477" transform="translate(0)">
                              <g id="Group_28476" data-name="Group 28476" clipPath="url(#clip-path-20)">
                                <path id="Path_180" data-name="Path 180" d="M85.049,94.171c-.073-.017-15.181-7.657-15.181-7.657a.163.163,0,0,1-.073-.219.161.161,0,0,1,.22-.073l15.107,7.64a.163.163,0,0,1-.073.309" transform="translate(-69.778 -86.203)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28481" data-name="Group 28481" transform="translate(17.514 1.699)">
                            <g id="Group_28480" data-name="Group 28480" transform="translate(0)">
                              <g id="Group_28479" data-name="Group 28479" clipPath="url(#clip-path-27)">
                                <path id="Path_181" data-name="Path 181" d="M84.421,93.805c-.073-.017-15.181-7.657-15.181-7.657a.163.163,0,0,1,.147-.292L84.494,93.5a.163.163,0,0,1-.073.309" transform="translate(-69.15 -85.838)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28484" data-name="Group 28484" transform="translate(23.285 3.534)">
                            <g id="Group_28483" data-name="Group 28483" transform="translate(0)">
                              <g id="Group_28482" data-name="Group 28482" clipPath="url(#clip-path-28)">
                                <path id="Path_182" data-name="Path 182" d="M79,92.587c-.073-.017-10.382-5.255-10.382-5.255a.165.165,0,0,1-.073-.22.16.16,0,0,1,.219-.071l10.309,5.238a.163.163,0,0,1-.073.309" transform="translate(-68.523 -87.02)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28487" data-name="Group 28487" transform="translate(23.1 2.373)">
                            <g id="Group_28486" data-name="Group 28486" transform="translate(0)">
                              <g id="Group_28485" data-name="Group 28485" clipPath="url(#clip-path-29)">
                                <path id="Path_183" data-name="Path 183" d="M79.527,92.433c-.073-.017-11.539-5.85-11.539-5.85a.164.164,0,0,1-.073-.22.16.16,0,0,1,.219-.071L79.6,92.124a.163.163,0,0,1-.074.309" transform="translate(-67.896 -86.272)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28490" data-name="Group 28490" transform="translate(20.433)">
                            <g id="Group_28489" data-name="Group 28489" transform="translate(0)">
                              <g id="Group_28488" data-name="Group 28488" clipPath="url(#clip-path-12)">
                                <path id="Path_184" data-name="Path 184" d="M82.541,92.709c-.073-.017-15.181-7.657-15.181-7.657a.162.162,0,1,1,.146-.29L82.613,92.4a.163.163,0,0,1-.073.309" transform="translate(-67.268 -84.743)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28493" data-name="Group 28493" transform="translate(24.139 0.822)">
                            <g id="Group_28492" data-name="Group 28492" transform="translate(0)">
                              <g id="Group_28491" data-name="Group 28491" clipPath="url(#clip-path-31)">
                                <path id="Path_185" data-name="Path 185" d="M79.18,91.851c-.073-.017-12.447-6.269-12.447-6.269a.162.162,0,0,1-.073-.219.16.16,0,0,1,.219-.071l12.374,6.25a.163.163,0,0,1-.073.309" transform="translate(-66.641 -85.273)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="Group_28568" data-name="Group 28568" transform="translate(82.772 141.554)" opacity="0.75">
                      <g id="Group_28567" data-name="Group 28567" transform="translate(0)">
                        <g id="Group_28566" data-name="Group 28566" clipPath="url(#clip-path-32)">
                          <g id="Group_28499" data-name="Group 28499" transform="translate(3.132 15.734)">
                            <g id="Group_28498" data-name="Group 28498" transform="translate(0)">
                              <g id="Group_28497" data-name="Group 28497" clipPath="url(#clip-path-33)">
                                <path id="Path_186" data-name="Path 186" d="M77.328,107.74a.173.173,0,0,1-.09-.026l-9.274-6.044a.165.165,0,0,1-.048-.227.167.167,0,0,1,.227-.048l9.276,6.044a.164.164,0,0,1-.09.3" transform="translate(-67.89 -101.369)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28502" data-name="Group 28502" transform="translate(1.45 13.454)">
                            <g id="Group_28501" data-name="Group 28501" transform="translate(0)">
                              <g id="Group_28500" data-name="Group 28500" clipPath="url(#clip-path-34)">
                                <path id="Path_187" data-name="Path 187" d="M79.357,107.984a.179.179,0,0,1-.09-.025L67.332,100.2a.164.164,0,1,1,.18-.275l11.935,7.757a.164.164,0,0,1-.09.3" transform="translate(-67.257 -99.901)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28505" data-name="Group 28505" transform="translate(0 11.309)">
                            <g id="Group_28504" data-name="Group 28504">
                              <g id="Group_28503" data-name="Group 28503" clipPath="url(#clip-path-35)">
                                <path id="Path_188" data-name="Path 188" d="M81.155,108.182a.173.173,0,0,1-.09-.026L66.7,98.819a.166.166,0,0,1-.048-.227.164.164,0,0,1,.227-.048l14.365,9.336a.164.164,0,0,1-.088.3" transform="translate(-66.626 -98.518)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28508" data-name="Group 28508" transform="translate(0.98 10.744)">
                            <g id="Group_28507" data-name="Group 28507" transform="translate(0)">
                              <g id="Group_28506" data-name="Group 28506" clipPath="url(#clip-path-36)">
                                <path id="Path_189" data-name="Path 189" d="M80.524,107.816a.171.171,0,0,1-.09-.025L66.07,98.452a.163.163,0,1,1,.178-.273l14.365,9.338a.163.163,0,0,1,.047.225.159.159,0,0,1-.137.074" transform="translate(-65.996 -98.154)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28511" data-name="Group 28511" transform="translate(1.958 10.178)">
                            <g id="Group_28510" data-name="Group 28510" transform="translate(0)">
                              <g id="Group_28509" data-name="Group 28509" clipPath="url(#clip-path-35)">
                                <path id="Path_190" data-name="Path 190" d="M79.894,107.453a.152.152,0,0,1-.088-.026L65.439,98.088a.163.163,0,1,1,.178-.273l14.367,9.336a.164.164,0,0,1-.09.3" transform="translate(-65.366 -97.789)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28514" data-name="Group 28514" transform="translate(2.937 9.613)">
                            <g id="Group_28513" data-name="Group 28513" transform="translate(0)">
                              <g id="Group_28512" data-name="Group 28512" clipPath="url(#clip-path-36)">
                                <path id="Path_191" data-name="Path 191" d="M79.263,107.088a.16.16,0,0,1-.09-.026L64.81,97.726a.164.164,0,0,1,.178-.275l14.363,9.336a.164.164,0,0,1-.088.3" transform="translate(-64.735 -97.425)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28517" data-name="Group 28517" transform="translate(3.915 9.047)">
                            <g id="Group_28516" data-name="Group 28516" transform="translate(0)">
                              <g id="Group_28515" data-name="Group 28515" clipPath="url(#clip-path-39)">
                                <path id="Path_192" data-name="Path 192" d="M78.633,106.723a.173.173,0,0,1-.09-.026L64.178,97.359a.163.163,0,0,1-.048-.225.165.165,0,0,1,.227-.048l14.367,9.336a.164.164,0,0,1-.09.3" transform="translate(-64.104 -97.06)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28520" data-name="Group 28520" transform="translate(10.156 11.925)">
                            <g id="Group_28519" data-name="Group 28519" transform="translate(0)">
                              <g id="Group_28518" data-name="Group 28518" transform="translate(0)" clipPath="url(#clip-path-40)">
                                <path id="Path_193" data-name="Path 193" d="M72.74,105.135a.16.16,0,0,1-.09-.026l-9.1-5.893a.164.164,0,0,1,.178-.275l9.1,5.895a.163.163,0,0,1-.09.3" transform="translate(-63.474 -98.915)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28523" data-name="Group 28523" transform="translate(5.873 7.917)">
                            <g id="Group_28522" data-name="Group 28522" transform="translate(0)">
                              <g id="Group_28521" data-name="Group 28521" clipPath="url(#clip-path-35)">
                                <path id="Path_194" data-name="Path 194" d="M77.371,105.994a.152.152,0,0,1-.088-.026L62.916,96.63a.161.161,0,0,1-.047-.225.163.163,0,0,1,.225-.048l14.367,9.336a.164.164,0,0,1-.09.3" transform="translate(-62.843 -96.332)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28526" data-name="Group 28526" transform="translate(6.852 7.351)">
                            <g id="Group_28525" data-name="Group 28525" transform="translate(0)">
                              <g id="Group_28524" data-name="Group 28524" clipPath="url(#clip-path-42)">
                                <path id="Path_195" data-name="Path 195" d="M76.739,105.631a.173.173,0,0,1-.09-.026L62.286,96.267a.163.163,0,0,1-.048-.225.165.165,0,0,1,.227-.05l14.363,9.338a.164.164,0,0,1-.088.3" transform="translate(-62.211 -95.967)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28529" data-name="Group 28529" transform="translate(7.83 6.785)">
                            <g id="Group_28528" data-name="Group 28528" transform="translate(0)">
                              <g id="Group_28527" data-name="Group 28527" clipPath="url(#clip-path-39)">
                                <path id="Path_196" data-name="Path 196" d="M76.109,105.265a.173.173,0,0,1-.09-.026L61.654,95.9a.164.164,0,0,1,.178-.275L76.2,104.964a.164.164,0,0,1-.09.3" transform="translate(-61.581 -95.602)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28532" data-name="Group 28532" transform="translate(8.81 6.22)">
                            <g id="Group_28531" data-name="Group 28531">
                              <g id="Group_28530" data-name="Group 28530" clipPath="url(#clip-path-36)">
                                <path id="Path_197" data-name="Path 197" d="M75.478,104.9a.164.164,0,0,1-.088-.026L61.025,95.537a.163.163,0,0,1-.048-.225.165.165,0,0,1,.227-.048L75.568,104.6a.164.164,0,0,1-.09.3" transform="translate(-60.951 -95.238)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28535" data-name="Group 28535" transform="translate(9.788 5.655)">
                            <g id="Group_28534" data-name="Group 28534" transform="translate(0)">
                              <g id="Group_28533" data-name="Group 28533" clipPath="url(#clip-path-35)">
                                <path id="Path_198" data-name="Path 198" d="M74.847,104.537a.164.164,0,0,1-.088-.026L60.392,95.175a.164.164,0,0,1,.178-.275l14.367,9.336a.164.164,0,0,1-.09.3" transform="translate(-60.318 -94.874)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28538" data-name="Group 28538" transform="translate(10.767 5.089)">
                            <g id="Group_28537" data-name="Group 28537" transform="translate(0)">
                              <g id="Group_28536" data-name="Group 28536" clipPath="url(#clip-path-35)">
                                <path id="Path_199" data-name="Path 199" d="M74.217,104.172a.16.16,0,0,1-.09-.026L59.762,94.808a.163.163,0,0,1-.048-.225.165.165,0,0,1,.227-.048l14.365,9.336a.164.164,0,0,1-.088.3" transform="translate(-59.688 -94.509)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28541" data-name="Group 28541" transform="translate(11.745 4.523)">
                            <g id="Group_28540" data-name="Group 28540" transform="translate(0)">
                              <g id="Group_28539" data-name="Group 28539" clipPath="url(#clip-path-39)">
                                <path id="Path_200" data-name="Path 200" d="M73.587,103.809a.173.173,0,0,1-.09-.026L59.132,94.445a.163.163,0,1,1,.178-.273l14.367,9.336a.164.164,0,0,1-.09.3" transform="translate(-59.058 -94.144)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28544" data-name="Group 28544" transform="translate(21.175 9.463)">
                            <g id="Group_28543" data-name="Group 28543" transform="translate(0)">
                              <g id="Group_28542" data-name="Group 28542" clipPath="url(#clip-path-48)">
                                <path id="Path_201" data-name="Path 201" d="M64.5,101.486a.16.16,0,0,1-.09-.026L58.5,97.627a.161.161,0,0,1-.048-.225.164.164,0,0,1,.227-.048l5.915,3.831a.164.164,0,0,1-.09.3" transform="translate(-58.427 -97.328)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28547" data-name="Group 28547" transform="translate(19.455 7.151)">
                            <g id="Group_28546" data-name="Group 28546" transform="translate(0)">
                              <g id="Group_28545" data-name="Group 28545" clipPath="url(#clip-path-49)">
                                <path id="Path_202" data-name="Path 202" d="M66.573,101.743a.16.16,0,0,1-.09-.026L57.87,96.139a.164.164,0,0,1,.178-.275l8.613,5.578a.164.164,0,0,1-.088.3" transform="translate(-57.795 -95.838)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28550" data-name="Group 28550" transform="translate(14.682 2.827)">
                            <g id="Group_28549" data-name="Group 28549" transform="translate(0)">
                              <g id="Group_28548" data-name="Group 28548" clipPath="url(#clip-path-35)">
                                <path id="Path_203" data-name="Path 203" d="M71.694,102.714a.16.16,0,0,1-.09-.026L57.239,93.352a.164.164,0,0,1,.178-.275l14.365,9.338a.161.161,0,0,1,.048.225.163.163,0,0,1-.137.074" transform="translate(-57.165 -93.052)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28553" data-name="Group 28553" transform="translate(15.662 2.262)">
                            <g id="Group_28552" data-name="Group 28552" transform="translate(0)">
                              <g id="Group_28551" data-name="Group 28551" clipPath="url(#clip-path-36)">
                                <path id="Path_204" data-name="Path 204" d="M71.062,102.35a.16.16,0,0,1-.09-.026L56.609,92.986a.163.163,0,0,1-.048-.225.165.165,0,0,1,.227-.048l14.365,9.336a.164.164,0,0,1-.09.3" transform="translate(-56.535 -92.688)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28556" data-name="Group 28556" transform="translate(16.64 1.697)">
                            <g id="Group_28555" data-name="Group 28555" transform="translate(0)">
                              <g id="Group_28554" data-name="Group 28554" clipPath="url(#clip-path-35)">
                                <path id="Path_205" data-name="Path 205" d="M70.431,101.986a.173.173,0,0,1-.09-.026L55.976,92.624a.164.164,0,0,1,.178-.275l14.367,9.336a.164.164,0,0,1-.09.3" transform="translate(-55.902 -92.323)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28559" data-name="Group 28559" transform="translate(17.619 1.131)">
                            <g id="Group_28558" data-name="Group 28558" transform="translate(0)">
                              <g id="Group_28557" data-name="Group 28557" clipPath="url(#clip-path-42)">
                                <path id="Path_206" data-name="Path 206" d="M69.8,101.622a.173.173,0,0,1-.09-.026L55.347,92.257a.163.163,0,0,1-.048-.225.167.167,0,0,1,.227-.05l14.365,9.338a.164.164,0,0,1-.09.3" transform="translate(-55.273 -91.959)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28562" data-name="Group 28562" transform="translate(25.301 4.939)">
                            <g id="Group_28561" data-name="Group 28561" transform="translate(0)">
                              <g id="Group_28560" data-name="Group 28560" clipPath="url(#clip-path-54)">
                                <path id="Path_207" data-name="Path 207" d="M62.467,99.7a.173.173,0,0,1-.09-.026l-7.66-4.964a.163.163,0,1,1,.178-.273l7.66,4.962a.164.164,0,0,1-.088.3" transform="translate(-54.642 -94.412)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                          <g id="Group_28565" data-name="Group 28565" transform="translate(19.576)">
                            <g id="Group_28564" data-name="Group 28564" transform="translate(0)">
                              <g id="Group_28563" data-name="Group 28563" clipPath="url(#clip-path-36)">
                                <path id="Path_208" data-name="Path 208" d="M68.537,100.892a.172.172,0,0,1-.088-.026L54.085,91.53a.164.164,0,1,1,.178-.275l14.365,9.336a.164.164,0,0,1,.047.227.168.168,0,0,1-.138.074" transform="translate(-54.01 -91.229)" fill="#3c3c3b" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="Group_28571" data-name="Group 28571" transform="translate(31.632 105.793)" opacity="0.5" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                      <g id="Group_28570" data-name="Group 28570" transform="translate(0)">
                        <g id="Group_28569" data-name="Group 28569" clipPath="url(#clip-path-56)">
                          <path id="Path_209" data-name="Path 209" d="M93.7,70.418c-6.02,2.982-6.02,7.816,0,10.8s15.78,2.982,21.8,0,6.02-7.814,0-10.8-15.779-2.982-21.8,0" transform="translate(-89.186 -68.182)" fill="#b58b69" />
                        </g>
                      </g>
                    </g>
                    <rect id="Rectangle_8905" data-name="Rectangle 8905" width="29.213" height="3.002" transform="translate(17.639 112.974)" fill="#91653c" />
                    <path id="Path_210" data-name="Path 210" d="M103.524,71.78c-5.7,3.293-5.7,8.633,0,11.926s14.953,3.294,20.657,0,5.7-8.633,0-11.926-14.953-3.293-20.657,0" transform="translate(-81.607 38.234)" fill="#8f2d2d" />
                    <path id="Path_211" data-name="Path 211" d="M124.186,82.425a15.173,15.173,0,0,1-1.938.995c-5.7,2.445-13.623,2.11-18.727-.995-5.7-3.479-5.7-9.12,0-12.59s14.953-3.479,20.665,0a10.894,10.894,0,0,1,2.515,2.059c3,3.369,2.161,7.681-2.515,10.531" transform="translate(-81.608 37.084)" fill="#ea4a49" />
                    <path id="Path_212" data-name="Path 212" d="M102.688,73.383a.82.82,0,0,0,0,1.553,2.978,2.978,0,0,0,2.691,0,.82.82,0,0,0,0-1.553,2.978,2.978,0,0,0-2.691,0" transform="translate(-63.56 40.303)" fill="#ededed" />
                    <path id="Path_213" data-name="Path 213" d="M102.892,73.416a.569.569,0,0,0,0,1.078,2.064,2.064,0,0,0,1.868,0,.569.569,0,0,0,0-1.078,2.064,2.064,0,0,0-1.868,0" transform="translate(-63.354 40.375)" fill="#8f2d2d" />
                    <path id="Path_214" data-name="Path 214" d="M102.8,72.81h2.056l.293,1.356h-2.641Z" transform="translate(-63.352 40.164)" fill="#8f2d2d" />
                    <path id="Path_215" data-name="Path 215" d="M102.994,72.6a.443.443,0,0,0,0,.839,1.6,1.6,0,0,0,1.455,0,.443.443,0,0,0,0-.839,1.6,1.6,0,0,0-1.455,0" transform="translate(-63.249 39.953)" fill="#8f2d2d" />
                    <rect id="Rectangle_8906" data-name="Rectangle 8906" width="4.211" height="21.249" transform="translate(40.658 80.552) rotate(-119.448)" fill="#91653c" />
                    <path id="Path_216" data-name="Path 216" d="M100.584,85.266c0,1.009,2.149.9,2.106,0V51.312a8.837,8.837,0,0,1,13.256-7.653l1.137.656,1.052-1.823L117,41.836a10.942,10.942,0,0,0-16.413,9.476Z" transform="translate(-73.359 22.272)" fill="#1d1d1b" />
                    <path id="Path_217" data-name="Path 217" d="M92.115,51.024c-2.436,4.126-.526,7.508,4.265,7.555a16.6,16.6,0,0,0,13.083-7.387c2.436-4.126.526-7.508-4.264-7.555a16.6,16.6,0,0,0-13.085,7.387" transform="translate(-50.965 24.071)" fill="#8f2d2d" />
                    <path id="Path_218" data-name="Path 218" d="M92.5,51.694l1.244-7.083,8.315-4.7,6.508,2.54c.186.051.365.109.535.174l.138.045c2.656,1.1,3.395,3.846,1.5,7.052a16.6,16.6,0,0,1-13.085,7.387c-3.93-.039-5.916-2.323-5.159-5.42" transform="translate(-54.228 22.019)" fill="#ea4a49" />
                    <path id="Path_219" data-name="Path 219" d="M106.766,36.11l3.674,6.508a2.473,2.473,0,0,1-.272,2.484,7.771,7.771,0,0,1-6.124,3.459,2.473,2.473,0,0,1-2.267-1.052L98.1,41Z" transform="translate(-62.04 19.919)" fill="#8f2d2d" />
                    <path id="Path_220" data-name="Path 220" d="M101.054,38.737c-1.14,1.932-.247,3.514,2,3.536a7.769,7.769,0,0,0,6.124-3.457c1.14-1.932.247-3.516-2-3.538a7.778,7.778,0,0,0-6.126,3.459" transform="translate(-64.856 19.46)" fill="#ea4a49" />
                    <path id="Path_221" data-name="Path 221" d="M102.832,37.533c-.428.726-.093,1.32.749,1.328a2.916,2.916,0,0,0,2.3-1.3c.428-.726.092-1.32-.751-1.33a2.921,2.921,0,0,0-2.3,1.3" transform="translate(-64.495 19.987)" fill="#8f2d2d" />
                    <path id="Path_222" data-name="Path 222" d="M106.372,84.1l-1.178.681L90.9,93.029,80.5,87.023,95.97,78.092l8.739,5.046.38.219,1.147.663Z" transform="translate(-30.436 43.078)" fill="#c6c6c6" />
                    <path id="Path_223" data-name="Path 223" d="M106.372,83.963,90.9,92.894,80.5,86.887,95.97,77.956Z" transform="translate(-30.436 43.003)" fill="#fff" />
                    <path id="Path_224" data-name="Path 224" d="M106.372,83.838,90.9,92.769,80.5,86.763,95.97,77.832Z" transform="translate(-30.436 42.934)" fill="#c6c6c6" />
                    <path id="Path_225" data-name="Path 225" d="M106.372,83.7,90.9,92.633,80.5,86.627,95.97,77.7Z" transform="translate(-30.436 42.859)" fill="#fff" />
                    <path id="Path_226" data-name="Path 226" d="M106.372,83.578,90.9,92.509,80.5,86.5,95.97,77.572Z" transform="translate(-30.436 42.791)" fill="#c6c6c6" />
                    <path id="Path_227" data-name="Path 227" d="M106.372,83.442,90.9,92.373,80.5,86.367,95.97,77.436Z" transform="translate(-30.436 42.716)" fill="#fff" />
                    <path id="Path_228" data-name="Path 228" d="M106.372,83.317,90.9,92.249,80.5,86.242,95.97,77.311Z" transform="translate(-30.436 42.647)" fill="#c6c6c6" />
                    <path id="Path_229" data-name="Path 229" d="M106.372,83.182,90.9,92.113,80.5,86.106,95.97,77.175Z" transform="translate(-30.436 42.572)" fill="#fff" />
                    <path id="Path_230" data-name="Path 230" d="M106.372,83.057,90.9,91.988,80.5,85.982,95.97,77.051Z" transform="translate(-30.436 42.503)" fill="#c6c6c6" />
                    <path id="Path_231" data-name="Path 231" d="M106.372,82.921,93.778,90.192a12.518,12.518,0,0,1-2.715,1.168l-.158.048L80.5,85.4a12.683,12.683,0,0,0,2.871-1.213l12.6-7.274Z" transform="translate(-30.437 42.429)" fill="#fff" />
                    <path id="Path_232" data-name="Path 232" d="M82.047,80.891a2.827,2.827,0,0,1-1.294,2.239,1.273,1.273,0,0,1-.635.189.805.805,0,0,1-.286-.05l1.178-.68-.138-.081a2.5,2.5,0,0,0,.749-1.542l.419-.141.006.065" transform="translate(-5.074 44.586)" fill="#3c3c3b" />
                    <path id="Path_233" data-name="Path 233" d="M80.745,80.741a.873.873,0,0,1,.425-.133c.279,0,.445.237.445.636,0,.076.417-.065.417-.065-.025-.6-.363-.993-.863-.993a1.286,1.286,0,0,0-.636.191,2.436,2.436,0,0,0-.7.633l.441.13a1.405,1.405,0,0,1,.467-.4" transform="translate(-5.066 44.233)" fill="#3c3c3b" />
                    <path id="Path_234" data-name="Path 234" d="M81.364,80.7a.107.107,0,0,1,0,.2.391.391,0,0,1-.352,0,.107.107,0,0,1,0-.2.391.391,0,0,1,.352,0" transform="translate(-6.18 44.492)" fill="#3c3c3b" />
                    <path id="Path_235" data-name="Path 235" d="M82.043,81.179c-.023-.6-.363-.993-.863-.993a1.276,1.276,0,0,0-.635.191,2.4,2.4,0,0,0-.692.627.107.107,0,0,0,.053.157.394.394,0,0,0,.354,0,1.715,1.715,0,0,1,.495-.419.89.89,0,0,1,.425-.133c.279,0,.445.237.445.636,0,.076.417-.065.417-.065" transform="translate(-5.076 44.233)" fill="#3c3c3b" />
                    <path id="Path_236" data-name="Path 236" d="M82.807,80.742c-.023-.594-.363-.991-.863-.991a1.273,1.273,0,0,0-.635.189,2.418,2.418,0,0,0-.692.627.107.107,0,0,0,.053.157.385.385,0,0,0,.354,0,1.715,1.715,0,0,1,.495-.419.89.89,0,0,1,.425-.133c.279,0,.445.239.445.636,0,.076.417-.065.417-.065" transform="translate(-7.026 43.993)" fill="#3c3c3b" />
                    <path id="Path_237" data-name="Path 237" d="M83.567,80.3c-.025-.594-.363-.991-.863-.991a1.273,1.273,0,0,0-.635.189,2.074,2.074,0,0,0-.692.627c-.039.056-.017.116.053.157a.348.348,0,0,0,.354,0,4.114,4.114,0,0,1,.495-.417.877.877,0,0,1,.425-.135c.279,0,.455.239.445.636,0,.076.417-.065.417-.065" transform="translate(-8.962 43.751)" fill="#3c3c3b" />
                    <path id="Path_238" data-name="Path 238" d="M84.324,79.866c-.025-.594-.363-.991-.863-.991a1.274,1.274,0,0,0-.635.189,2.09,2.09,0,0,0-.692.627c-.039.056-.017.116.053.157a.352.352,0,0,0,.354,0,4.3,4.3,0,0,1,.495-.419.89.89,0,0,1,.425-.133c.279,0,.455.239.445.636,0,.076.417-.065.417-.065" transform="translate(-10.894 43.51)" fill="#3c3c3b" />
                    <path id="Path_239" data-name="Path 239" d="M85.082,79.43c-.025-.6-.363-.993-.863-.993a1.276,1.276,0,0,0-.635.191,2.086,2.086,0,0,0-.692.625c-.039.057-.017.118.053.158a.352.352,0,0,0,.354,0,4.3,4.3,0,0,1,.495-.419.89.89,0,0,1,.425-.133c.279,0,.455.237.445.636,0,.076.417-.065.417-.065" transform="translate(-12.828 43.268)" fill="#3c3c3b" />
                    <path id="Path_240" data-name="Path 240" d="M85.839,78.992c-.025-.6-.363-.993-.863-.993a1.286,1.286,0,0,0-.636.191,2.084,2.084,0,0,0-.69.627c-.039.056-.017.116.053.157a.352.352,0,0,0,.354,0,4.3,4.3,0,0,1,.495-.419.89.89,0,0,1,.425-.133c.279,0,.455.237.445.636,0,.076.417-.065.417-.065" transform="translate(-14.759 43.027)" fill="#3c3c3b" />
                    <path id="Path_241" data-name="Path 241" d="M86.6,78.554c-.025-.6-.363-.993-.863-.993a1.286,1.286,0,0,0-.636.191,2.084,2.084,0,0,0-.69.627c-.039.056-.017.116.053.157a.352.352,0,0,0,.354,0,4.3,4.3,0,0,1,.495-.419.89.89,0,0,1,.425-.133c.279,0,.455.237.445.636,0,.076.417-.065.417-.065" transform="translate(-16.693 42.785)" fill="#3c3c3b" />
                    <path id="Path_242" data-name="Path 242" d="M87.354,78.116c-.025-.6-.363-.993-.863-.993a1.286,1.286,0,0,0-.636.191,2.084,2.084,0,0,0-.69.627c-.039.056-.017.116.053.157a.352.352,0,0,0,.354,0,4.433,4.433,0,0,1,.495-.419.89.89,0,0,1,.425-.133c.279,0,.455.239.445.636,0,.076.417-.065.417-.065" transform="translate(-18.625 42.543)" fill="#3c3c3b" />
                    <path id="Path_243" data-name="Path 243" d="M88.11,77.678c-.025-.6-.363-.993-.863-.993a1.286,1.286,0,0,0-.636.191,2.434,2.434,0,0,0-.69.627.107.107,0,0,0,.053.157.394.394,0,0,0,.354,0,1.715,1.715,0,0,1,.495-.419.89.89,0,0,1,.425-.133c.279,0,.445.237.445.636,0,.076.417-.065.417-.065" transform="translate(-20.557 42.302)" fill="#3c3c3b" />
                    <path id="Path_244" data-name="Path 244" d="M36.665,93.065l7.128,4.116a.7.7,0,0,0,.633,0L58.079,89.3a.192.192,0,0,0,0-.365l-7.13-4.116a.7.7,0,0,0-.632,0L36.665,92.7a.192.192,0,0,0,0,.365" transform="translate(85.948 46.746)" fill="#4a4a49" />
                    <path id="Path_245" data-name="Path 245" d="M58.209,87.1v.709L36.533,91.589v-.706Z" transform="translate(85.949 48.049)" fill="#4a4a49" />
                    <path id="Path_246" data-name="Path 246" d="M36.665,92.609l7.128,4.116a.7.7,0,0,0,.633,0l13.653-7.882a.193.193,0,0,0,0-.366l-7.13-4.115a.7.7,0,0,0-.632,0L36.665,92.244a.192.192,0,0,0,0,.365" transform="translate(85.948 46.495)" fill="#1d1d1b" />
                    <path id="Path_247" data-name="Path 247" d="M44.39,95.914l12.283-7.091L53.51,87l-1.047-.608-1.618-.936-.585-.335-.563-.324L37.412,91.885Z" transform="translate(86.123 46.774)" fill="#52c9de" />
                    <path id="Path_248" data-name="Path 248" d="M38.51,85.841l1.761,1.016a.286.286,0,0,0,.261,0l.456-.264c.073-.04.073-.109,0-.151l-1.76-1.016a.289.289,0,0,0-.262,0l-.456.264a.08.08,0,0,0,0,.15" transform="translate(100.131 47.107)" fill="#fff" />
                    <path id="Path_249" data-name="Path 249" d="M40.3,86.8a.148.148,0,0,0,.13,0l.456-.264c0-.076-1.761-1.092-1.761-1.092a.148.148,0,0,0-.13,0l-.456.264c0,.076,1.761,1.092,1.761,1.092" transform="translate(100.172 47.126)" fill="#4a4a49" />
                    <path id="Path_250" data-name="Path 250" d="M47.352,91.119c.029.079.079.079.109.062l.424-.244c-.029-.081-.078-.081-.109-.064Z" transform="translate(79.488 50.124)" fill="#273340" />
                    <path id="Path_251" data-name="Path 251" d="M47.5,91.034c.031.079.079.079.109.062l.424-.244c-.029-.081-.079-.081-.109-.064Z" transform="translate(79.112 50.077)" fill="#273340" />
                    <path id="Path_252" data-name="Path 252" d="M47.646,90.949c.031.081.079.081.109.062l.424-.244c-.029-.081-.078-.081-.109-.064Z" transform="translate(78.737 50.03)" fill="#273340" />
                    <path id="Path_253" data-name="Path 253" d="M47.793,90.864c.029.079.079.079.109.062l.424-.244c-.029-.081-.079-.081-.109-.064Z" transform="translate(78.362 49.983)" fill="#273340" />
                    <path id="Path_254" data-name="Path 254" d="M47.94,90.779c.029.081.079.081.109.064l.424-.245c-.029-.081-.079-.081-.109-.064Z" transform="translate(77.987 49.936)" fill="#273340" />
                    <g id="Group_28574" data-name="Group 28574" transform="translate(126.84 140.989)" opacity="0.5">
                      <g id="Group_28573" data-name="Group 28573" transform="translate(0)">
                        <g id="Group_28572" data-name="Group 28572" clipPath="url(#clip-path-57)">
                          <path id="Path_255" data-name="Path 255" d="M47.352,91.119c.029.079.079.079.109.062l.424-.244c-.029-.081-.078-.081-.109-.064Z" transform="translate(-47.352 -90.865)" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_28577" data-name="Group 28577" transform="translate(126.611 140.857)" opacity="0.5">
                      <g id="Group_28576" data-name="Group 28576" transform="translate(0)">
                        <g id="Group_28575" data-name="Group 28575" clipPath="url(#clip-path-57)">
                          <path id="Path_256" data-name="Path 256" d="M47.5,91.034c.031.079.079.079.109.062l.424-.244c-.029-.081-.079-.081-.109-.064Z" transform="translate(-47.499 -90.78)" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_28580" data-name="Group 28580" transform="translate(126.383 140.725)" opacity="0.5">
                      <g id="Group_28579" data-name="Group 28579" transform="translate(0)">
                        <g id="Group_28578" data-name="Group 28578" transform="translate(0)" clipPath="url(#clip-path-57)">
                          <path id="Path_257" data-name="Path 257" d="M47.646,90.949c.031.081.079.081.109.062l.424-.244c-.029-.081-.078-.081-.109-.064Z" transform="translate(-47.646 -90.695)" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_28583" data-name="Group 28583" transform="translate(126.155 140.594)" opacity="0.5">
                      <g id="Group_28582" data-name="Group 28582" transform="translate(0)">
                        <g id="Group_28581" data-name="Group 28581" clipPath="url(#clip-path-57)">
                          <path id="Path_258" data-name="Path 258" d="M47.793,90.864c.029.079.079.079.109.062l.424-.244c-.029-.081-.079-.081-.109-.064Z" transform="translate(-47.793 -90.61)" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_28586" data-name="Group 28586" transform="translate(125.927 140.462)" opacity="0.5">
                      <g id="Group_28585" data-name="Group 28585" transform="translate(0)">
                        <g id="Group_28584" data-name="Group 28584" transform="translate(0)" clipPath="url(#clip-path-57)">
                          <path id="Path_259" data-name="Path 259" d="M47.94,90.779c.029.081.079.081.109.064l.424-.245c-.029-.081-.079-.081-.109-.064Z" transform="translate(-47.939 -90.525)" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_28589" data-name="Group 28589" transform="translate(129.037 142.267)" opacity="0.5">
                      <g id="Group_28588" data-name="Group 28588" transform="translate(0)">
                        <g id="Group_28587" data-name="Group 28587" clipPath="url(#clip-path-62)">
                          <path id="Path_260" data-name="Path 260" d="M46.08,91.722a.086.086,0,0,0,0,.163.312.312,0,0,0,.282,0,.086.086,0,0,0,0-.163.312.312,0,0,0-.282,0" transform="translate(-46.021 -91.689)" fill="#fff" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_28592" data-name="Group 28592" transform="translate(128.004 141.671)" opacity="0.5">
                      <g id="Group_28591" data-name="Group 28591" transform="translate(0)">
                        <g id="Group_28590" data-name="Group 28590" transform="translate(0)" clipPath="url(#clip-path-62)">
                          <path id="Path_261" data-name="Path 261" d="M46.746,91.338a.086.086,0,0,0,0,.163.312.312,0,0,0,.282,0,.086.086,0,0,0,0-.163.312.312,0,0,0-.282,0" transform="translate(-46.688 -91.304)" />
                        </g>
                      </g>
                    </g>
                    <rect id="Rectangle_8914" data-name="Rectangle 8914" width="9.091" height="2.966" transform="matrix(-0.866, -0.5, 0.5, -0.866, 113.008, 131.105)" fill="#333332" />
                    <path id="Path_262" data-name="Path 262" d="M67.835,72.322,37.386,89.815c0,1.375.946,1.921,2.115,1.246L65.72,76.009a4.67,4.67,0,0,0,2.115-3.662Z" transform="translate(75 39.895)" fill="#333332" />
                    <path id="Path_263" data-name="Path 263" d="M37.386,75.177,67.753,57.819l38.974,22.5L76.278,97.816Z" transform="translate(36.109 31.895)" fill="#575756" />
                    <path id="Path_264" data-name="Path 264" d="M54.888,79l-1.732,1L55.3,81.239l1.73-1Z" transform="translate(61.337 43.58)" fill="#dadada" />
                    <path id="Path_265" data-name="Path 265" d="M56.46,78.1,58.6,79.332l1.732-1-2.143-1.237Z" transform="translate(52.905 42.528)" fill="#dadada" />
                    <path id="Path_266" data-name="Path 266" d="M59.845,76.141l-1.732,1,2.141,1.237,1.732-1Z" transform="translate(48.688 42.002)" fill="#dadada" />
                    <path id="Path_267" data-name="Path 267" d="M61.5,75.187l-1.732,1,2.143,1.237,1.732-1Z" transform="translate(44.472 41.475)" fill="#dadada" />
                    <path id="Path_268" data-name="Path 268" d="M63.15,74.233l-1.732,1,2.141,1.237,1.732-1Z" transform="translate(40.255 40.949)" fill="#dadada" />
                    <path id="Path_269" data-name="Path 269" d="M64.722,73.325l2.143,1.237,1.732-1-2.143-1.237Z" transform="translate(31.823 39.896)" fill="#dadada" />
                    <path id="Path_270" data-name="Path 270" d="M68.107,71.371l-1.732,1,2.143,1.235,1.73-1Z" transform="translate(27.606 39.37)" fill="#dadada" />
                    <path id="Path_271" data-name="Path 271" d="M69.758,70.417l-1.732,1,2.141,1.237,1.733-1Z" transform="translate(23.391 38.844)" fill="#dadada" />
                    <path id="Path_272" data-name="Path 272" d="M65.9,66.371l1.732-1-2.141-1.237-1.732,1Z" transform="translate(34.288 35.379)" fill="#dadada" />
                    <path id="Path_273" data-name="Path 273" d="M67.552,65.417l1.732-1-2.143-1.237-1.732,1Z" transform="translate(30.071 34.853)" fill="#dadada" />
                    <path id="Path_274" data-name="Path 274" d="M70.936,63.463l-2.143-1.237-1.732,1L69.2,64.464Z" transform="translate(25.855 34.326)" fill="#dadada" />
                    <path id="Path_275" data-name="Path 275" d="M48.309,76.942l1.732-1L47.9,74.706l-1.732,1Z" transform="translate(79.169 41.21)" fill="#dadada" />
                    <path id="Path_276" data-name="Path 276" d="M49.963,75.988l1.732-1-2.143-1.237-1.732,1Z" transform="translate(74.951 40.683)" fill="#dadada" />
                    <path id="Path_277" data-name="Path 277" d="M49.473,69.982l3.885,2.244.821.473.422.244,1.071.619,1.493.861,2.143,1.237,2.563,1.48,1.732-1-7.023-4.054-.247-.143L51.2,68.981Z" transform="translate(60.48 38.052)" fill="#dadada" />
                    <path id="Path_278" data-name="Path 278" d="M59.877,70.264l1.732-1-2.141-1.235-1.733,1Z" transform="translate(49.653 37.526)" fill="#dadada" />
                    <path id="Path_279" data-name="Path 279" d="M61.528,69.309l1.732-1-2.141-1.237-1.732,1Z" transform="translate(45.439 37)" fill="#dadada" />
                    <path id="Path_280" data-name="Path 280" d="M64.914,67.356l-2.143-1.237-1.732,1,2.143,1.237Z" transform="translate(41.221 36.473)" fill="#dadada" />
                    <path id="Path_281" data-name="Path 281" d="M69.019,66.173l-2.141-1.237-1.733,1,2.143,1.237Z" transform="translate(30.745 35.821)" fill="#dadada" />
                    <path id="Path_282" data-name="Path 282" d="M70.671,65.219l-2.143-1.237-1.732,1L68.94,66.22Z" transform="translate(26.529 35.294)" fill="#dadada" />
                    <path id="Path_283" data-name="Path 283" d="M72.324,64.265l-2.141-1.237-1.733,1,2.143,1.237Z" transform="translate(22.313 34.768)" fill="#dadada" />
                    <path id="Path_284" data-name="Path 284" d="M51.43,76.744l-2.141-1.237-1.733,1L49.7,77.743Z" transform="translate(75.626 41.652)" fill="#dadada" />
                    <path id="Path_285" data-name="Path 285" d="M49.208,75.553,51.35,76.79l1.732-1-2.143-1.237Z" transform="translate(71.411 41.125)" fill="#dadada" />
                    <path id="Path_286" data-name="Path 286" d="M50.86,74.6,53,75.835l1.733-1L52.591,73.6Z" transform="translate(67.195 40.599)" fill="#dadada" />
                    <path id="Path_287" data-name="Path 287" d="M52.512,73.645l2.141,1.237,1.733-1-2.143-1.238Z" transform="translate(62.978 40.073)" fill="#dadada" />
                    <path id="Path_288" data-name="Path 288" d="M54.165,72.69l2.143,1.237,1.732-1L55.9,71.691Z" transform="translate(58.762 39.547)" fill="#dadada" />
                    <path id="Path_289" data-name="Path 289" d="M55.817,71.736l2.141,1.237,1.733-1-2.143-1.237Z" transform="translate(54.546 39.021)" fill="#dadada" />
                    <path id="Path_290" data-name="Path 290" d="M57.47,70.782l2.141,1.237,1.732-1L59.2,69.783Z" transform="translate(50.328 38.494)" fill="#dadada" />
                    <path id="Path_291" data-name="Path 291" d="M59.122,69.828l2.141,1.237,1.733-1-2.143-1.237Z" transform="translate(46.113 37.968)" fill="#dadada" />
                    <path id="Path_292" data-name="Path 292" d="M60.775,68.874l2.143,1.237,1.732-1-2.143-1.237Z" transform="translate(41.896 37.442)" fill="#dadada" />
                    <path id="Path_293" data-name="Path 293" d="M70.407,66.974l-2.143-1.237-1.732,1,2.143,1.237Z" transform="translate(27.204 36.263)" fill="#dadada" />
                    <path id="Path_294" data-name="Path 294" d="M68.185,65.783l2.141,1.237,1.733-1-2.143-1.237Z" transform="translate(22.988 35.737)" fill="#dadada" />
                    <path id="Path_295" data-name="Path 295" d="M73.712,65.066l-2.143-1.237-1.732,1,2.141,1.238Z" transform="translate(18.772 35.21)" fill="#dadada" />
                    <path id="Path_296" data-name="Path 296" d="M52.818,77.545l-2.143-1.237-1.732,1,2.143,1.237Z" transform="translate(72.084 42.094)" fill="#dadada" />
                    <path id="Path_297" data-name="Path 297" d="M50.6,76.354l2.143,1.237,1.732-1-2.143-1.235Z" transform="translate(67.869 41.568)" fill="#dadada" />
                    <path id="Path_298" data-name="Path 298" d="M56.123,75.637,53.98,74.4l-1.732,1,2.143,1.237Z" transform="translate(63.652 41.041)" fill="#dadada" />
                    <path id="Path_299" data-name="Path 299" d="M57.775,74.683l-2.141-1.237-1.733,1,2.143,1.238Z" transform="translate(59.435 40.515)" fill="#dadada" />
                    <path id="Path_300" data-name="Path 300" d="M59.427,73.729l-2.143-1.237-1.732,1L57.7,74.728Z" transform="translate(55.219 39.989)" fill="#dadada" />
                    <path id="Path_301" data-name="Path 301" d="M61.08,72.775l-2.141-1.237-1.733,1,2.143,1.237Z" transform="translate(51.003 39.463)" fill="#dadada" />
                    <path id="Path_302" data-name="Path 302" d="M58.858,71.584,61,72.82l1.732-1-2.143-1.237Z" transform="translate(46.787 38.936)" fill="#dadada" />
                    <path id="Path_303" data-name="Path 303" d="M64.385,70.867,62.244,69.63l-1.733,1,2.143,1.237Z" transform="translate(42.57 38.41)" fill="#dadada" />
                    <path id="Path_304" data-name="Path 304" d="M64.3,70.4l2.563,1.48,1.733-1L66.035,69.4l2.154-1.243L66.048,66.92l-2.154,1.244-.7.4-1.029.594,1.431.825Z" transform="translate(35.791 36.915)" fill="#dadada" />
                    <path id="Path_305" data-name="Path 305" d="M70.062,68.775l1.732-1-2.141-1.237-1.732,1Z" transform="translate(23.663 36.705)" fill="#dadada" />
                    <path id="Path_306" data-name="Path 306" d="M71.3,65.585l-1.732,1,2.141,1.237,1.733-1Z" transform="translate(19.447 36.179)" fill="#dadada" />
                    <path id="Path_307" data-name="Path 307" d="M75.1,65.867l-2.143-1.237-1.732,1,2.143,1.237Z" transform="translate(15.23 35.652)" fill="#dadada" />
                    <path id="Path_308" data-name="Path 308" d="M54.206,78.346,52.063,77.11l-1.732,1,2.143,1.237Z" transform="translate(68.543 42.536)" fill="#dadada" />
                    <path id="Path_309" data-name="Path 309" d="M54.125,78.392l1.732-1-2.141-1.237-1.732,1Z" transform="translate(64.327 42.01)" fill="#dadada" />
                    <path id="Path_310" data-name="Path 310" d="M57.511,76.438,55.368,75.2l-1.732,1,2.143,1.238Z" transform="translate(60.11 41.484)" fill="#dadada" />
                    <path id="Path_311" data-name="Path 311" d="M57.021,74.248l-1.732,1,2.141,1.237,1.732-1Z" transform="translate(55.894 40.957)" fill="#dadada" />
                    <path id="Path_312" data-name="Path 312" d="M60.816,74.53l-2.143-1.237-1.732,1,2.143,1.237Z" transform="translate(51.677 40.431)" fill="#dadada" />
                    <path id="Path_313" data-name="Path 313" d="M62.468,73.576l-2.143-1.237-1.732,1,2.143,1.238Z" transform="translate(47.461 39.905)" fill="#dadada" />
                    <path id="Path_314" data-name="Path 314" d="M62.387,73.622l1.732-1-2.141-1.237-1.732,1Z" transform="translate(43.246 39.379)" fill="#dadada" />
                    <path id="Path_315" data-name="Path 315" d="M65.773,71.668,63.63,70.431l-1.732,1,2.143,1.237Z" transform="translate(39.028 38.852)" fill="#dadada" />
                    <path id="Path_316" data-name="Path 316" d="M67.426,69.76l-.782-.452-1.361-.785-1.732,1,1.361.787.782.45,2.563,1.48,1.732-1Z" transform="translate(32.248 37.8)" fill="#dadada" />
                    <path id="Path_317" data-name="Path 317" d="M70.7,69.142l2.141,1.237,1.733-1-2.143-1.238Z" transform="translate(16.58 37.589)" fill="#dadada" />
                    <path id="Path_318" data-name="Path 318" d="M74.08,67.188l-1.732,1,2.141,1.237,1.733-1Z" transform="translate(12.363 37.063)" fill="#dadada" />
                    <path id="Path_319" data-name="Path 319" d="M75.734,66.234l-1.732,1,2.141,1.237,1.732-1Z" transform="translate(8.146 36.536)" fill="#dadada" />
                    <path id="Path_320" data-name="Path 320" d="M63.136,68.929,58,71.9a.934.934,0,0,1-.846,0L49.4,67.419a.257.257,0,0,1,0-.487l5.139-2.968a.946.946,0,0,1,.847,0l7.752,4.476a.258.258,0,0,1,0,.489" transform="translate(61.157 35.229)" fill="#878787" />
                    <path id="Path_321" data-name="Path 321" d="M74.107,57.888a19.559,19.559,0,0,1,.956,5.736l-.832,2.929-1.766,3.083c-.948,1.635-2.22.77-1.53-.953L72.2,66V63.625L68.217,67.91l-3.361,5.274c-1.151,1.15-1.559.658-1.656-.524l-1.15,1.451c-.988,1.232-2.222.092-1.791-.9l-.431.369c-.934,1.185-2.22.185-1.5-1.244l.546-.976c-1.151.791-1.108-.52-.093-2.161l3.176-5.856c.649-1.327,5.274-6.045,7.459-6.908,1.837-.748,3.381-.172,4.692,1.449" transform="translate(35.766 30.955)" fill="#ffaa6d" />
                    <path id="Path_322" data-name="Path 322" d="M60.853,72.754c-1.041,3.308-2.974,4.881-6.414,7.373l-6.414,4.192c-1.924.574-2.045-.248-1.48-1.6L43.9,84.543c-1.792,1.092-3.235-.109-2.41-1.455l-1.727.739c-1.657.445-2.354-.5-.74-2.343l1.029-.987c-1.483.731-3.023,0-1.064-1.989A72.645,72.645,0,0,1,49.258,68.9a22.447,22.447,0,0,1,8.306-2.22l5.632-1.85,2.838,5.181Z" transform="translate(75.757 35.763)" fill="#ffaa6d" />
                    <path id="Path_323" data-name="Path 323" d="M56.306,25.849c5.757,4.782,7.308,9.58,6.25,19.737C60.132,68.846,50.622,83.8,50.622,83.8c-9.963,5.459-23.954,8.267-38.888,10.456.185-2.959-.644-5.179-3.289-6.073,13.4-6.414,28.057-15.391,33.5-14.967C42.281,61.4,44.146,52,46.767,41.474c1.918-7.454,4.743-13.29,9.539-15.625" transform="translate(124.876 14.259)" fill="#308dc2" />
                    <path id="Path_324" data-name="Path 324" d="M92.107,80.228,83.721,49.583a4.368,4.368,0,0,0-1.5,3.873l7.266,26.583c.309,1.122,1.472,1.215,2.6.208Z" transform="translate(-18.723 27.351)" fill="#1d1d1b" />
                    <path id="Path_325" data-name="Path 325" d="M97.295,72.069l6.365,31.255L64.712,80.837,58.348,49.583Z" transform="translate(6.651 27.351)" fill="#1d1d1b" />
                    <path id="Path_326" data-name="Path 326" d="M66.988,94.73,58.6,64.085a4.368,4.368,0,0,0-1.5,3.873L64.37,94.54c.309,1.122,1.473,1.215,2.6.208Z" transform="translate(45.37 35.351)" fill="#333332" />
                    <path id="Path_327" data-name="Path 327" d="M77.5,69.1c.219,1.324-.6,1.86-1.836,1.2a5.685,5.685,0,0,1-2.624-3.594c-.219-1.324.6-1.86,1.834-1.2A5.687,5.687,0,0,1,77.5,69.1" transform="translate(10.03 35.981)" fill="#dadada" />
                    <path id="Path_328" data-name="Path 328" d="M103.643,84.517l6.689,3.94.369.217.777.456,1.224-2.192-.815-.455-.335-.185L104.8,82.537Z" transform="translate(-72.673 45.53)" fill="#e83345" />
                    <path id="Path_329" data-name="Path 329" d="M108.681,83.393c0,.324,1.148.995,1.429.833a1.124,1.124,0,0,0,.506-.878c0-.324-1.148-.995-1.427-.833a1.119,1.119,0,0,0-.507.878" transform="translate(-78.404 45.504)" fill="#824639" />
                    <path id="Path_330" data-name="Path 330" d="M103.466,87c0,.566.4.8.889.514a1.967,1.967,0,0,0,.889-1.539c0-.568-.4-.8-.889-.514A1.967,1.967,0,0,0,103.466,87" transform="translate(-64.941 47.075)" fill="#824639" />
                    <path id="Path_331" data-name="Path 331" d="M104.2,86.848a1.321,1.321,0,0,0,.029.27l.369.219a.633.633,0,0,1-.169-.489,2.031,2.031,0,0,1,.915-1.589.791.791,0,0,1,.379-.119c.062.006-.273-.18-.273-.18a1.15,1.15,0,0,0-.22.1,2.344,2.344,0,0,0-1.03,1.787" transform="translate(-66.572 46.866)" fill="#fff" />
                    <path id="Path_332" data-name="Path 332" d="M108.6,84.009c0,.518.363.728.813.469a1.793,1.793,0,0,0,.812-1.406c0-.518-.363-.729-.812-.47a1.793,1.793,0,0,0-.813,1.407" transform="translate(-77.877 45.504)" fill="#e83345" />
                    <path id="Path_333" data-name="Path 333" d="M97.783,90c0,.309.216.433.483.279a1.069,1.069,0,0,0,.484-.836c0-.309-.217-.433-.484-.279a1.067,1.067,0,0,0-.483.836" transform="translate(-49.628 49.149)" fill="#eb9481" />
                    <path id="Path_334" data-name="Path 334" d="M97.871,86.8a1.538,1.538,0,0,1,.7-1.2.474.474,0,0,1,.507-.036,62.8,62.8,0,0,1,9.063,5.559l-.7,1.171A64.1,64.1,0,0,1,98.1,87.261a.512.512,0,0,1-.233-.462" transform="translate(-59.152 47.165)" fill="#e83345" />
                    <path id="Path_335" data-name="Path 335" d="M104.982,82.984l5.234,3.023a.565.565,0,0,0,.509,0l.161-.093c.085-.05.127-.079.106-.3-.106.006-5.339-3.016-5.339-3.016a.565.565,0,0,0-.509,0l-.161.093c-.11.011-.106.14-.1.157a.181.181,0,0,0,.1.137" transform="translate(-72.883 45.53)" fill="#824639" />
                    <path id="Path_336" data-name="Path 336" d="M104.987,82.886l5.234,3.023a.565.565,0,0,0,.509,0l.161-.093c.141-.082.141-.213,0-.293L105.657,82.5a.556.556,0,0,0-.509,0l-.161.093a.154.154,0,0,0,0,.293" transform="translate(-72.888 45.475)" fill="#98584a" />
                    <path id="Path_337" data-name="Path 337" d="M97.453,89.754a.281.281,0,0,1,.1-.318,6.958,6.958,0,0,1,.922.462.277.277,0,0,1-.095.326,6.911,6.911,0,0,1-.919-.462l-.009-.008" transform="translate(-48.841 49.336)" fill="#3c3c3b" />
                    <path id="Path_338" data-name="Path 338" d="M102.139,90.849l.43-1.106-.43-.61-.858.495-.43,1.106.43.61Z" transform="translate(-58.209 49.168)" fill="#b2b2b2" />
                    <path id="Path_339" data-name="Path 339" d="M111.154,85.653l.43-1.106-.43-.61-.858.495-.43,1.106.43.611Z" transform="translate(-81.211 46.302)" fill="#b2b2b2" />
                    <path id="Path_340" data-name="Path 340" d="M101.129,84.433l13.988,8.062.858-.5-13.988-8.062Z" transform="translate(-72.044 46.302)" fill="#375d68" />
                    <path id="Path_341" data-name="Path 341" d="M101.682,85.362l13.988,8.062.428-1.106L102.11,84.256Z" transform="translate(-73.026 46.478)" fill="#284952" />
                    <path id="Path_342" data-name="Path 342" d="M102.111,85.58,116.1,93.641l-.43-.61-13.988-8.062Z" transform="translate(-73.026 46.871)" fill="#284952" />
                    <path id="Path_343" data-name="Path 343" d="M99.682,89.628l3.1,2.16-2.245-2.656Z" transform="translate(-56.609 49.168)" fill="#fff" />
                    <path id="Path_344" data-name="Path 344" d="M99.681,90.558l3.533,1.055-3.1-2.161Z" transform="translate(-57.038 49.344)" fill="#dadada" />
                    <path id="Path_345" data-name="Path 345" d="M100.111,90.774l3.1.444-3.533-1.054Z" transform="translate(-57.039 49.738)" fill="#b2b2b2" />
                    <path id="Path_346" data-name="Path 346" d="M100.572,91.672a.234.234,0,0,0,.118-.436L99.415,90.5a.2.2,0,0,0-.242.113c-.064.112-.079.227.008.292l1.275.737a.242.242,0,0,0,.116.031" transform="translate(-53.76 49.916)" fill="#3c3c3b" />
                    <path id="Path_347" data-name="Path 347" d="M102.239,89.218l-.858.5c-.534-.4-.213-1.114.858-.5" transform="translate(-58.308 49.082)" fill="#fff" />
                    <path id="Path_348" data-name="Path 348" d="M102.63,89.5,102.2,90.6c-.973-.563-.428-1.6.43-1.106" transform="translate(-59.558 49.298)" fill="#dadada" />
                    <path id="Path_349" data-name="Path 349" d="M102.067,90.2l.43.61c-.909-.524-1.068-.92-.43-.61" transform="translate(-59.424 49.698)" fill="#b2b2b2" />
                    <path id="Path_350" data-name="Path 350" d="M106.417,88.656c0,.137.3-.036.3-.036a1.1,1.1,0,0,1,1,0l.585.337,1.33.768h1.632v1.108a.349.349,0,0,1-.206.295l-1.462.843a1.1,1.1,0,0,1-1,0l-1.806-1.041-1.535-.888a.333.333,0,0,1-.2-.317l0-1.069Z" transform="translate(-73.412 48.82)" fill="#c6c6c6" />
                    <path id="Path_351" data-name="Path 351" d="M105.254,89.334l1.555.9,1.784,1.032a1.108,1.108,0,0,0,1,0l1.462-.843a.306.306,0,0,0,0-.577l-1.82-1.05-1.521-.878a1.108,1.108,0,0,0-1,0l-1.46.843a.3.3,0,0,0,0,.577" transform="translate(-73.412 48.43)" fill="#fff" />
                    <path id="Path_352" data-name="Path 352" d="M107.931,88.695v1.069a.349.349,0,0,0,.208.317l1.535.888V89.891l-1.291-1.2-.282-.264a.333.333,0,0,0-.169.264" transform="translate(-76.296 48.781)" fill="#824639" />
                    <path id="Path_353" data-name="Path 353" d="M106.352,89.046a.352.352,0,0,0,.208.289l1.535.908,1.511-.9.937-.554-1.522-.878a1.1,1.1,0,0,0-1,0l-1.462.843c-.15.1-.208.2-.208.289" transform="translate(-74.717 48.43)" fill="#e83345" />
                    <path id="Path_354" data-name="Path 354" d="M93.536,104.784c-2.627,1.516-2.627,3.975,0,5.491a10.515,10.515,0,0,0,9.511,0c2.625-1.516,2.625-3.975,0-5.491a10.515,10.515,0,0,0-9.511,0" transform="translate(-46.249 57.175)" fill="#fff" />
                    <rect id="Rectangle_8915" data-name="Rectangle 8915" width="13.451" height="11.145" transform="translate(45.318 153.559)" fill="#fff" />
                    <path id="Path_355" data-name="Path 355" d="M93.72,97.684c-2.627,1.516-2.884,3.828-.258,5.345a10.515,10.515,0,0,0,9.511,0c2.625-1.518,2.467-3.764-.16-5.28a9.662,9.662,0,0,0-9.094-.065" transform="translate(-46.175 53.276)" fill="#c22b3a" />
                    <path id="Path_356" data-name="Path 356" d="M102.929,109.639a3.823,3.823,0,0,0,1.147-.18.76.76,0,0,0-.455-1.451,1.913,1.913,0,0,1-1.766-.242c-.853-.664-1.26-2.245-1.144-4.453.076-1.431.926-1.5,1.167-1.508a1.053,1.053,0,0,1,.745.512,7.507,7.507,0,0,1,.667,1.7.76.76,0,0,0,1.463-.411,8.979,8.979,0,0,0-.867-2.135,2.522,2.522,0,0,0-1.96-1.182,2.784,2.784,0,0,0-2.732,2.945c-.147,2.8.434,4.729,1.729,5.735a3.24,3.24,0,0,0,2.006.672" transform="translate(-57.815 55.321)" fill="#ea4a49" />
                    <path id="Path_357" data-name="Path 357" d="M93.534,97.594a10.605,10.605,0,0,1,9.513,0c2.628,1.517,2.628,3.974,0,5.491a10.477,10.477,0,0,1-9.513,0c-2.628-1.518-2.628-3.974,0-5.491m.329,4.943a2.377,2.377,0,0,0,.281.141,9.941,9.941,0,0,0,8.293,0,2.379,2.379,0,0,0,.281-.141,2.275,2.275,0,0,0,0-4.38,9.869,9.869,0,0,0-8.855,0,2.275,2.275,0,0,0,0,4.38" transform="translate(-46.245 53.215)" fill="#c22b3a" />
                    <path id="Path_358" data-name="Path 358" d="M93.226,100.821a9.922,9.922,0,0,0,8.292,0,9.212,9.212,0,0,0-8.292,0" transform="translate(-45.326 55.072)" fill="#1d1d1b" />
                  </g>
                </g>
              </g>
            </g>
            <g id="User" transform="translate(85.417 4782.829)">
              <g id="Group_28409" data-name="Group 28409" transform="translate(364.363 231.621)">
                <g id="Group_28408" data-name="Group 28408" clipPath="url(#clip-path-64)">
                  <g id="Group_28407" data-name="Group 28407" transform="translate(0 0)">
                    <g id="Group_28406" data-name="Group 28406" clipPath="url(#clip-path-64)">
                      <path id="Path_92" data-name="Path 92" d="M2.461,61.96,7.1,106.334c-.268,3.433,1.866,6.916,6.408,9.539,8.574,4.951,22.477,4.951,31.049,0,4.289-2.476,6.432-5.72,6.432-8.963L55.607,61.96Z" transform="translate(1.418 35.695)" fill="#7d4b34" />
                      <path id="Path_93" data-name="Path 93" d="M47.949,55.789c10.423,6.018,10.423,15.775,0,21.793s-27.323,6.018-37.744,0S-.22,61.807,10.2,55.789s27.322-6.019,37.744,0" transform="translate(1.375 29.54)" fill="#deaa81" />
                      <path id="Path_94" data-name="Path 94" d="M46.479,69.067a13.086,13.086,0,0,1-5.036,4.755c-8.051,4.65-21.117,4.65-29.166,0a13.081,13.081,0,0,1-5.037-4.755c-2.306-4.106-.627-8.817,5.037-12.075,8.049-4.653,21.115-4.653,29.166,0,5.663,3.258,7.341,7.969,5.036,12.075" transform="translate(3.595 30.823)" fill="#85664d" />
                      <path id="Path_95" data-name="Path 95" d="M46.114,66.391a13.086,13.086,0,0,1-5.036,4.755c-8.051,4.65-21.117,4.65-29.166,0a13.081,13.081,0,0,1-5.037-4.755,13.089,13.089,0,0,1,5.037-4.754c8.049-4.651,21.115-4.651,29.166,0a13.1,13.1,0,0,1,5.036,4.754" transform="translate(3.961 33.5)" fill="#1d1d1b" />
                      <path id="Path_96" data-name="Path 96" d="M14.033,25.082a8.629,8.629,0,0,1,5.122.039,57.178,57.178,0,0,1-.063-8.341,17.743,17.743,0,0,0-6.947-3.671,7.771,7.771,0,0,1,7.244,1.138,28.045,28.045,0,0,1,1.548-5.983,2.942,2.942,0,0,1,.813-1.341,1.7,1.7,0,0,1,.194-.115,3.817,3.817,0,0,1-.81-1.734,4.327,4.327,0,0,1,2.95,1.471A7.379,7.379,0,0,1,26.831,7.6a14.584,14.584,0,0,0-.071-5.365,6.507,6.507,0,0,1,1.418,4.812,11.2,11.2,0,0,1-.2,1.433,9.456,9.456,0,0,1,.734.731c.3-.131.591-.273.9-.375a7.71,7.71,0,0,1,6.07.363,9.921,9.921,0,0,0-5.773,1.647c.129.218.257.433.367.659a17.561,17.561,0,0,1,1.448,8.235c0,.095-.016.188-.017.282a6.1,6.1,0,0,1,2.125-.137,6.17,6.17,0,0,1,3.885,2.051,9.89,9.89,0,0,0-3.129-.98,6.152,6.152,0,0,0-2.977.572,92.325,92.325,0,0,1-1.439,9.948,11.484,11.484,0,0,1,1.762,5.444,10.16,10.16,0,0,0-2.2-3c-.153.843-.322,1.686-.454,2.528a47.259,47.259,0,0,0-.657,6.421c-.027,1.262,0,2.526.05,3.789C29.118,54.129,31.3,61.5,31.883,69c.024.448.05.868.077,1.316.474,8.446-.894,16.866-.842,25.287.025,1.946.1,3.921.315,5.868a14.464,14.464,0,0,1-2.263,1,11.684,11.684,0,0,1-2.315.686,14.608,14.608,0,0,1-4.394.288c-.736-.079-1.524-1.474-2.131-2.657-.29-.553-.553-1.053-.736-1.343a3.457,3.457,0,0,1-.449-1.6c-.919-9.71,3.16-19.392,2.079-29.076-.34-3.132-1.236-6.21-1.551-9.367-.528-5.237.526-10.5.709-15.763.162-5.207-.536-10.516-1.01-15.774a7.465,7.465,0,0,0-4.052-.8c-2.443.233-4.6,1.644-6.635,3.012a8.645,8.645,0,0,1,5.349-4.988" transform="translate(5.003 1.287)" fill="#ba8a62" />
                      <g id="Group_28393" data-name="Group 28393" transform="translate(25.333 57.493)" opacity="0.4">
                        <g id="Group_28392" data-name="Group 28392">
                          <g id="Group_28391" data-name="Group 28391" clipPath="url(#clip-path-66)">
                            <path id="Path_97" data-name="Path 97" d="M22.6,83.421a14.664,14.664,0,0,1-4.394.29c-.736-.08-1.526-1.474-2.131-2.659,0-3,1.184-5.893,1.737-8.892.842-4.58.158-9.315.92-13.92a53.03,53.03,0,0,0,.9-5.368c.21-2.736-.5-5.472-1-8.21-.5-2.709-.79-5.6.184-8.183a39.934,39.934,0,0,0,1.157,8.84c.37,1.316.9,2.58,1.237,3.895,1.21,4.553.526,9.368-.288,14a30.45,30.45,0,0,0-.605,4.632,25.786,25.786,0,0,0,.184,3.5c.448,4.052,1.262,8.078,2.1,12.078" transform="translate(-16.073 -36.478)" fill="#1d1d1b" />
                          </g>
                        </g>
                      </g>
                      <g id="Group_28396" data-name="Group 28396" transform="translate(31.617 69.914)" opacity="0.4">
                        <g id="Group_28395" data-name="Group 28395">
                          <g id="Group_28394" data-name="Group 28394" clipPath="url(#clip-path-67)">
                            <path id="Path_98" data-name="Path 98" d="M24.88,77.2a14.472,14.472,0,0,1-2.263,1c-.473-1.316-.92-2.632-1.341-3.975a28.936,28.936,0,0,1-.868-3.288c-.9-4.736.158-9.553.736-14.341a41.4,41.4,0,0,0-.052-12.235,32.975,32.975,0,0,1,2.394,9.6c.184,2.921-.342,5.816-.5,8.736-.158,2.894.052,5.973,1.5,8.5.025.052.052.079.079.131.025,1.946.106,3.921.315,5.868" transform="translate(-20.061 -44.358)" fill="#1d1d1b" />
                          </g>
                        </g>
                      </g>
                      <g id="Group_28399" data-name="Group 28399" transform="translate(30.937 35.654)" opacity="0.4">
                        <g id="Group_28398" data-name="Group 28398">
                          <g id="Group_28397" data-name="Group 28397" clipPath="url(#clip-path-68)">
                            <path id="Path_99" data-name="Path 99" d="M25.655,58.565c-1.184-2.367-2.815-4.631-3.737-7.13-1.816-4.869-.763-10.316-1.447-15.473-.158-1.316-.448-2.605-.632-3.92a24.253,24.253,0,0,1,.605-9.42,42.472,42.472,0,0,0,1.685,11.5l.236.79c.448,7.474,2.632,14.841,3.211,22.34.025.448.052.868.079,1.316" transform="translate(-19.629 -22.621)" fill="#1d1d1b" />
                          </g>
                        </g>
                      </g>
                      <g id="Group_28402" data-name="Group 28402" transform="translate(26.378 20.679)" opacity="0.4">
                        <g id="Group_28401" data-name="Group 28401">
                          <g id="Group_28400" data-name="Group 28400" clipPath="url(#clip-path-69)">
                            <path id="Path_100" data-name="Path 100" d="M18.86,30.484a33.4,33.4,0,0,0,.928,8.208c.329,1.368.745,2.717.961,4.107a29.016,29.016,0,0,1,.262,3.549l.345,10.593c-1.206-2.462-.526-5.423-1.154-8.09-.489-2.082-1.754-3.9-2.339-5.961a23.434,23.434,0,0,1-.47-6.386C17.372,28.66,15.584,20.581,18,13.12c-.5,4.244.857,8.483.963,12.755.038,1.537-.085,3.072-.1,4.609" transform="translate(-16.736 -13.12)" fill="#1d1d1b" />
                          </g>
                        </g>
                      </g>
                      <g id="Group_28405" data-name="Group 28405" transform="translate(30.302 15.787)" opacity="0.4">
                        <g id="Group_28404" data-name="Group 28404">
                          <g id="Group_28403" data-name="Group 28403" clipPath="url(#clip-path-70)">
                            <path id="Path_101" data-name="Path 101" d="M24.1,26.988c-.315,1.656-.632,3.315-.9,4.973a10.344,10.344,0,0,0-1.236-3.474c-.448-.63-1.106-1.13-1.579-1.735-1.526-1.973-1.237-4.736-.868-7.184q.712-4.776,1.42-9.551c.184,4.446.369,9,1.921,13.183a28.182,28.182,0,0,1,1.132,3.157,5.507,5.507,0,0,1,.106.632" transform="translate(-19.226 -10.017)" fill="#1d1d1b" />
                          </g>
                        </g>
                      </g>
                      <path id="Path_102" data-name="Path 102" d="M3.276,25.386c1.051-2.9,2.777-5.709,5.46-7.23s6.451-1.39,8.511.906c2.37-.288,4.489,2.014,4.649,4.4a8.379,8.379,0,0,1-2.9,6.3,63.262,63.262,0,0,1-5.7,4.33A27.177,27.177,0,0,0,4.808,45.213,24.467,24.467,0,0,1,3.276,25.386" transform="translate(1.073 9.881)" fill="#2e872a" />
                      <path id="Path_103" data-name="Path 103" d="M7.091,9.274A14.188,14.188,0,0,1,12.6,5.64a7.8,7.8,0,0,1,6.43.548,5.433,5.433,0,0,1,2.553,5.68,6.7,6.7,0,0,1-3.961,4.161,58.064,58.064,0,0,1-5.66,1.8A20.326,20.326,0,0,0,.085,28.518,24.634,24.634,0,0,1,7.091,9.274" transform="translate(0 2.996)" fill="#2e872a" />
                      <path id="Path_104" data-name="Path 104" d="M29.891,20.278C32.936,22.422,34.262,26.3,34.7,30a29.915,29.915,0,0,1-2.668,16.265,24.212,24.212,0,0,0-9.394-13.028c-2.121-1.467-4.64-2.776-5.568-5.184A6.54,6.54,0,0,1,18.633,21.5a8.869,8.869,0,0,1,11.258-1.225" transform="translate(9.637 10.834)" fill="#2e872a" />
                      <path id="Path_105" data-name="Path 105" d="M37.838,17.444a25.421,25.421,0,0,1,2.619,17.748A19.1,19.1,0,0,0,29.978,21.3c-1.913-.906-4.169-1.656-5.091-3.562a4.537,4.537,0,0,1,2.137-5.677,6.661,6.661,0,0,1,6.4.441,12.975,12.975,0,0,1,4.415,4.946" transform="translate(14.133 6.584)" fill="#2e872a" />
                      <path id="Path_106" data-name="Path 106" d="M28.463,3.772a12.517,12.517,0,0,1,10.818,9.113,4.361,4.361,0,0,0-3.759-2.776,14.6,14.6,0,0,0-4.889.566,10.317,10.317,0,0,1-4.894.216,3.908,3.908,0,0,1-3.045-3.45,3.645,3.645,0,0,1,2.021-3.058,6.779,6.779,0,0,1,3.748-.612" transform="translate(13.074 2.153)" fill="#2e872a" />
                      <path id="Path_107" data-name="Path 107" d="M12.846,3.847a10.421,10.421,0,0,1,8.982-3.8A5.8,5.8,0,0,1,25.7,1.773a3.153,3.153,0,0,1,.243,3.978c-.99,1.155-2.768,1.157-4.26.865a34.62,34.62,0,0,1-4.47-1.292L12.846,3.847" transform="translate(7.401 0)" fill="#2e872a" />
                      <path id="Path_108" data-name="Path 108" d="M14,8.881a7.366,7.366,0,0,1,11.928,2.741,6.025,6.025,0,0,1-.016,4.563c-.786,1.753-2.482,2.886-3.786,4.3-2.011,2.181-3.084,5.045-4.11,7.832-2.411-2.774-4.829-5.683-5.969-9.178S11.325,11.4,14,8.881" transform="translate(6.598 3.969)" fill="#41bf3c" />
                      <path id="Path_109" data-name="Path 109" d="M29.148,5.82a11.141,11.141,0,0,1,4.577,11.72A9.831,9.831,0,0,0,28.42,13.4c-1.466-.47-3.026-.586-4.492-1.059A4.92,4.92,0,0,1,20.56,9.445c-.607-1.94.865-4.1,2.8-4.73a6.923,6.923,0,0,1,5.789,1.1" transform="translate(11.765 2.571)" fill="#41bf3c" />
                      <path id="Path_110" data-name="Path 110" d="M25.146,3.621c.8,1.209,1.058,2.96.014,3.969-1.138,1.1-2.973.774-4.547.589A16.891,16.891,0,0,0,8.673,11.39,25.713,25.713,0,0,1,15.2,3c1.267-1.069,7.671-2.805,9.948.626" transform="translate(4.997 0.92)" fill="#41bf3c" />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_28390" data-name="Group 28390" transform="translate(422.934 175.844)">
                <g id="Group_28389" data-name="Group 28389" clipPath="url(#clip-path-71)">
                  <g id="Group_28388" data-name="Group 28388">
                    <g id="Group_28387" data-name="Group 28387" clipPath="url(#clip-path-71)">
                      <path id="Path_1" data-name="Path 1" d="M70.011,114.693c12.335,7.122,12.335,18.67,0,25.792s-32.336,7.123-44.673,0-12.335-18.67,0-25.792,32.338-7.122,44.673,0" transform="translate(8.83 60.023)" fill="#575756" />
                      <path id="Path_2" data-name="Path 2" d="M70.011,113.4c12.335,7.123,12.335,18.67,0,25.792s-32.336,7.123-44.673,0S13,120.527,25.338,113.4s32.338-7.122,44.673,0" transform="translate(8.83 59.315)" fill="#faeedf" />
                      <path id="Path_3" data-name="Path 3" d="M71.389,127.338l-8.215.18-30.888.692-13.11.3q2.507-2.005,4.668-3.939a59.614,59.614,0,0,0,7.9-8.5c4.919-6.591,7.1-13.373,7.343-21.791.023-1.146.023-2.316-.012-3.533-.036-1.386-.119-2.83-.251-4.311H53.157c-.108,1.826-.167,3.558-.155,5.229.011.836.023,1.66.082,2.46.431,8.692,3,15.57,9.027,23.224a92.583,92.583,0,0,0,6.806,7.569c.788.788,1.612,1.6,2.472,2.424" transform="translate(10.526 47.442)" fill="#faeedf" />
                      <path id="Path_4" data-name="Path 4" d="M44.111,84.967c2.808,1.62,2.808,4.249,0,5.87a11.241,11.241,0,0,1-10.167,0c-2.808-1.622-2.808-4.25,0-5.87a11.241,11.241,0,0,1,10.167,0" transform="translate(17.476 45.971)" fill="#fff" />
                      <g id="Group_28365" data-name="Group 28365" transform="translate(34.37 141.185)" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                        <g id="Group_28364" data-name="Group 28364">
                          <g id="Group_28363" data-name="Group 28363" clipPath="url(#clip-path-73)">
                            <path id="Path_5" data-name="Path 5" d="M67.264,122.324a34.447,34.447,0,0,1-5.743,2.6,52.277,52.277,0,0,1-30.89.694,36.909,36.909,0,0,1-7.952-3.344c-.166-.094-.333-.191-.489-.3a59.347,59.347,0,0,0,7.9-8.5c4.919-6.591,7.1-13.373,7.343-21.791a54.321,54.321,0,0,1,13.994-.155c.431,8.692,3,15.57,9.027,23.224a92.014,92.014,0,0,0,6.806,7.569" transform="translate(-22.19 -91.152)" fill="#dadada" />
                          </g>
                        </g>
                      </g>
                      <path id="Path_6" data-name="Path 6" d="M107.083,120.61,86.9,132.254l-6.989,4.035c-7.394,4.278-18.85,5.114-27.879,2.065l-17.121-5.816C23.8,128.774,4.357,115.469.081,65.22A26.252,26.252,0,0,1,1.349,55.045,34.116,34.116,0,0,1,16.2,36.37,54.04,54.04,0,0,1,32.869,27.9C48.05,23.3,60.76,34.184,64.62,43.225l7.7,17.959C80.6,79.617,97.584,91.424,107.487,99.992c5.627,4.857,6.99,16.339-.4,20.617" transform="translate(0 14.714)" fill="#ea4a49" />
                      <g id="Group_28368" data-name="Group 28368" transform="translate(34.644 105.376)" opacity="0.5" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                        <g id="Group_28367" data-name="Group 28367">
                          <g id="Group_28366" data-name="Group 28366" clipPath="url(#clip-path-74)">
                            <path id="Path_7" data-name="Path 7" d="M75.293,73.276c12.108,6.99,12.108,18.325,0,25.315s-31.738,6.99-43.846,0-12.108-18.325,0-25.315,31.738-6.99,43.846,0" transform="translate(-22.367 -68.033)" fill="#ea4a49" />
                          </g>
                        </g>
                      </g>
                      <g id="Group_28371" data-name="Group 28371" transform="translate(0 69.759)" opacity="0.5" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                        <g id="Group_28370" data-name="Group 28370">
                          <g id="Group_28369" data-name="Group 28369" clipPath="url(#clip-path-75)">
                            <path id="Path_8" data-name="Path 8" d="M86.9,122.247l-6.989,4.035c-7.4,4.278-18.852,5.114-27.879,2.065l-17.123-5.816C23.8,118.765,4.359,105.462.081,55.213A26.282,26.282,0,0,1,1.349,45.038a119.8,119.8,0,0,0,4.71,27.378c4.168,13.723,11.522,26.691,22.3,36.15,15.679,13.762,38.375,18.917,58.534,13.681" transform="translate(0 -45.038)" fill="#ea4a49" />
                          </g>
                        </g>
                      </g>
                      <g id="Group_28374" data-name="Group 28374" transform="translate(57.197 56.276)" opacity="0.5" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                        <g id="Group_28373" data-name="Group 28373">
                          <g id="Group_28372" data-name="Group 28372" clipPath="url(#clip-path-76)">
                            <path id="Path_9" data-name="Path 9" d="M43.672,61.069c.675,3.855,1.351,7.823.542,11.652s-3.465,7.535-7.286,8.38a48.4,48.4,0,0,1,30,4.242c-13.8-10.28-22.953-32.706-28.576-49.01,1.369,8.367,4.235,18.536,5.322,24.736" transform="translate(-36.928 -36.333)" fill="#ea4a49" />
                          </g>
                        </g>
                      </g>
                      <g id="Group_28377" data-name="Group 28377" transform="translate(6.568 74.855)" opacity="0.5" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                        <g id="Group_28376" data-name="Group 28376">
                          <g id="Group_28375" data-name="Group 28375" clipPath="url(#clip-path-77)">
                            <path id="Path_10" data-name="Path 10" d="M4.24,48.328a134.209,134.209,0,0,0,8.886,32.185c3.566,7.941,8.4,15.452,15.038,21.09A14.344,14.344,0,0,1,33.9,83.29a15.058,15.058,0,0,1-10.607-2.506,33.33,33.33,0,0,1-7.941-7.766C11.191,67.7,6.6,56.762,4.24,48.328" transform="translate(-4.241 -48.328)" fill="#ea4a49" />
                          </g>
                        </g>
                      </g>
                      <path id="Path_11" data-name="Path 11" d="M50.264,42.206l-.936-7.548L38.653,43.45l2.656,9.911L63.833,68.545l7.534-2.655c-8.508-9.253-14.67-18.334-21.1-23.684" transform="translate(21.216 19.024)" fill="#ffaa6d" />
                      <path id="Path_12" data-name="Path 12" d="M50.46,34.689c-.3-11.491-5.373-17.063-11.841-17.063L34.489,28.719,40.939,52.8c5.492-1.809,9.255-4.622,10.675-8.79Z" transform="translate(18.931 9.675)" fill="#fff" />
                      <path id="Path_13" data-name="Path 13" d="M84.629,123.66,90,130.028a19.975,19.975,0,0,0,2.6,10.457,2.755,2.755,0,0,0,2.133,1.358,15.785,15.785,0,0,0,8.1-1.764c5.929,1.97,12.323.691,18.906-1.891,3.79-1.619,5.966-3.945,6.987-6.778a2.493,2.493,0,0,0-.313-2.268l-.166-.239a4.77,4.77,0,0,0-3.943-2.043l-12.814.084-9.324-4.355L93.882,116.3l-9.253,6.965Z" transform="translate(46.453 63.835)" fill="#1d1d1b" />
                      <path id="Path_14" data-name="Path 14" d="M58.389,135.214v4.534a14.141,14.141,0,0,0-1.791,8.9,6.325,6.325,0,0,0,4.577,3.682c3.119,7.638,7.82,11.479,13.6,12.737,4.413.658,7.5.158,8.688-1.99,1.293-4.227-2.057-8.576-5.771-12.935a24.969,24.969,0,0,1-4.483-10.159l-1.586-7.751H58.389Z" transform="translate(31.001 72.58)" fill="#1d1d1b" />
                      <path id="Path_15" data-name="Path 15" d="M88.62,141.579c-.429,11.295-1.1,23.4-.824,35.82-7.463,2.675-16.525,1.493-16.812-2.447-1.027-19.223-4.513-37.767-1.732-49.672a21.724,21.724,0,0,1,1.924-5.325L57.814,106.176,30.639,78.141,37.206,73.2l8.74-6.592.9-.68,10.949-2.113c.226.155.454.3.669.452.477.324.943.646,1.4.991,1.349.968,2.65,1.995,3.9,3.068A67.381,67.381,0,0,1,74.854,80.494c1.433,2.006,2.759,4.07,3.988,6.172A92.473,92.473,0,0,1,83.379,95.6c1.445,3.3,2.711,6.64,3.821,10.006a87.764,87.764,0,0,1,1.575,13.565c.167,3.366.2,6.865.167,10.483-.036,3.857-.167,7.833-.322,11.928" transform="translate(16.818 35.027)" fill="#22709c" />
                      <path id="Path_16" data-name="Path 16" d="M137.156,151.3a25.958,25.958,0,0,1-8.393,9.468,9.761,9.761,0,0,1-2.949,1.313,3.858,3.858,0,0,1-3.57-.753c-10.794-10.28-21.17-21.361-27.951-31.773-4.513-6.913-7.452-13.539-7.87-19.426l-23.258-4.047-18.675-3.26c-13.564-1.791-23.212-11.7-23.582-24.285a26.243,26.243,0,0,1,.6-6.46l29.79-5.564,12.514-2.34,1-.191a3.083,3.083,0,0,0,.393,1.182c.8,1.41,2.591,2.316,3.9,3.068a14.072,14.072,0,0,1-2.436,3.761,15.113,15.113,0,0,1-6.053,3.94A90.371,90.371,0,0,1,84.19,86.575c2.926,1.814,5.969,3.809,9.5,6.053,1.039.657,2.125,1.337,3.271,2.054a12.215,12.215,0,0,1,3.785,3.713c13.528,20.848,25.146,39.44,36.405,52.907" transform="translate(11.468 35.121)" fill="#308dc2" />
                      <g id="Group_28380" data-name="Group 28380" transform="translate(32.376 102.28)" opacity="0.5" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                        <g id="Group_28379" data-name="Group 28379">
                          <g id="Group_28378" data-name="Group 28378" clipPath="url(#clip-path-78)">
                            <path id="Path_17" data-name="Path 17" d="M128.757,159.645l-2.949,1.313a3.858,3.858,0,0,1-3.57-.753c-10.794-10.28-21.17-21.361-27.951-31.772-.036,3.857-.167,7.833-.322,11.928a66.841,66.841,0,0,0-19.367-16.3,21.7,21.7,0,0,1,1.922-5.325L63.158,104.959,44.485,101.7C30.92,99.908,21.273,90,20.9,77.414l7.4-.263L42.55,71.98,58.9,66.034,66.67,70.87,80.2,79.276c1.434,2.006,2.759,4.072,3.988,6.174,2.926,1.814,5.971,3.809,9.5,6.053a30.423,30.423,0,0,0-4.967,2.878c-3.772,2.746-6.4,6.185-5.754,9.194l7.188,3.1a59.554,59.554,0,0,0,3.964,11.271c6.507,14.089,18.65,27.964,34.638,41.695" transform="translate(-20.902 -66.034)" fill="#308dc2" />
                          </g>
                        </g>
                      </g>
                      <path id="Path_18" data-name="Path 18" d="M76.778,94.187a45.268,45.268,0,0,0-7.605,2.292C53.3,102.724,39,116.108,28.648,106.079,26.3,92.526,22.965,79.74,17.711,68.469l-4.084-10L6.965,42.2A67.566,67.566,0,0,1,32.827,18.116c7.762-1.6,14.973-2.1,20.895,0L61.09,36.029l3.188,12.738c3.38,15.523,2.388,31.642,12.5,45.42" transform="translate(3.823 9.179)" fill="#fff" />
                      <g id="Group_28383" data-name="Group 28383" transform="translate(17.451 61.683)" opacity="0.2" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                        <g id="Group_28382" data-name="Group 28382">
                          <g id="Group_28381" data-name="Group 28381" clipPath="url(#clip-path-79)">
                            <path id="Path_19" data-name="Path 19" d="M69.773,82.008,66.812,83.8c-15.868,6.245-30.172,19.629-40.524,9.6C23.935,79.847,20.6,67.06,15.35,55.788l-4.083-10,3.94-5.969C19.756,53.03,24.688,65.543,32.663,72.73,37,76.909,42.669,79.751,49.906,81.017Z" transform="translate(-11.266 -39.824)" fill="#575756" />
                          </g>
                        </g>
                      </g>
                      <path id="Path_20" data-name="Path 20" d="M48.96,76.7c-2.2.163-32.7-12.589-32.2-13.418A41.534,41.534,0,0,0,19.1,55.246L3.354,52c-.29,4.95-.223,10.22-.025,15.607C6.61,80.024,27.827,81.5,46.274,83.8c0,0,3.281-7.147,2.686-7.1" transform="translate(1.734 28.544)" fill="#ffaa6d" />
                      <path id="Path_21" data-name="Path 21" d="M19.232,14.037,17.376,25.994s6.572,9.871,13,9.2c8.914-.931,6-9.2,6-9.2l-1.12-10.749Z" transform="translate(9.538 7.705)" fill="#ffaa6d" />
                      <g id="Group_28386" data-name="Group 28386" transform="translate(32.064 30.083)" opacity="0.3" style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
                        <g id="Group_28385" data-name="Group 28385">
                          <g id="Group_28384" data-name="Group 28384" clipPath="url(#clip-path-80)">
                            <path id="Path_22" data-name="Path 22" d="M20.7,21.57l16.762-2.148s4.445,16.33-2.91,19.121C24.208,42.468,20.7,21.57,20.7,21.57" transform="translate(-20.702 -19.422)" fill="#ffaa6d" />
                          </g>
                        </g>
                      </g>
                      <path id="Path_23" data-name="Path 23" d="M21.348,26.177a12.149,12.149,0,0,0,.787,4.072,23.439,23.439,0,0,0,1.487,3.267,29.3,29.3,0,0,0,10.749,6.2,4.084,4.084,0,0,0,3.824-1.033c2.47-2.661,4.106-7.174,5.375-12.507l1.19-6.821C44.435,9.184,41,1.613,30.031.337c-6.959,1.324-11.566,5.235-13.025,12.61a22.072,22.072,0,0,0,.937,10.44Z" transform="translate(9.184 0.185)" fill="#ffaa6d" />
                      <path id="Path_24" data-name="Path 24" d="M18.247,28.258l2.706,1.825-.077-4.14,2.636.652,1.55-3.72c6.666,4.385,18.6,2.512,20.388-2.6A16.078,16.078,0,0,0,39.7,3.51C36.587,1.147,33.511-.538,28.782.159,21.755,1.2,17.48,5.98,16.224,13.182c-.274,3.422.829,11.326,2.023,15.075" transform="translate(8.882 0)" fill="#1d1d1b" />
                      <path id="Path_25" data-name="Path 25" d="M21.752,19.123a6.313,6.313,0,0,0-.714-3.566c-.5-.832-1.767-2.066-2.714-1.258C16.979,15.446,18,20.218,19.348,21c2.034,1.179,2.4-.448,2.4-1.877" transform="translate(9.711 7.704)" fill="#ffaa6d" />
                      <path id="Path_26" data-name="Path 26" d="M20.574,53.276c-.533-12.8-3.609-20.62-10.286-21.383-4.633,6.831-7.452,14.07-5.982,22.278a71.535,71.535,0,0,0-1.021,8.871c3.527,2.387,9.177,3.164,15.748,3.243a97.65,97.65,0,0,0,1.541-13.009" transform="translate(1.803 17.506)" fill="#fff" />
                      <rect id="Rectangle_8839" data-name="Rectangle 8839" width="9.52" height="3.106" transform="matrix(0.866, -0.5, 0.5, 0.866, 77.218, 134.595)" fill="#333332" />
                      <path id="Path_27" data-name="Path 27" d="M30.693,75.865,62.575,94.182c0,1.437-.991,2.01-2.213,1.3L32.908,79.725a4.892,4.892,0,0,1-2.215-3.834Z" transform="translate(16.847 41.642)" fill="#333332" />
                      <path id="Path_28" data-name="Path 28" d="M103.3,78.828,71.5,60.653,30.693,84.215l31.882,18.317Z" transform="translate(16.847 33.292)" fill="#575756" />
                      <path id="Path_29" data-name="Path 29" d="M49.477,82.873l1.814,1.047-2.243,1.295-1.814-1.047Z" transform="translate(25.927 45.489)" fill="#dadada" />
                      <path id="Path_30" data-name="Path 30" d="M54.757,81.919l-2.243,1.295L50.7,82.166l2.243-1.295Z" transform="translate(27.829 44.39)" fill="#dadada" />
                      <path id="Path_31" data-name="Path 31" d="M54.676,79.871l1.814,1.047-2.243,1.295-1.814-1.047Z" transform="translate(28.781 43.841)" fill="#dadada" />
                      <path id="Path_32" data-name="Path 32" d="M56.41,78.87l1.814,1.047-2.243,1.295-1.814-1.047Z" transform="translate(29.732 43.292)" fill="#dadada" />
                      <path id="Path_33" data-name="Path 33" d="M58.143,77.87l1.814,1.047-2.243,1.295L55.9,79.165Z" transform="translate(30.683 42.742)" fill="#dadada" />
                      <path id="Path_34" data-name="Path 34" d="M63.423,76.915,61.18,78.21l-1.814-1.047,2.243-1.295Z" transform="translate(32.586 41.644)" fill="#dadada" />
                      <path id="Path_35" data-name="Path 35" d="M63.343,74.867l1.812,1.047-2.243,1.295L61.1,76.162Z" transform="translate(33.538 41.094)" fill="#dadada" />
                      <path id="Path_36" data-name="Path 36" d="M65.076,73.867l1.814,1.047-2.243,1.295-1.814-1.047Z" transform="translate(34.489 40.545)" fill="#dadada" />
                      <path id="Path_37" data-name="Path 37" d="M60.167,69.619l-1.814-1.047L60.6,67.277l1.814,1.047Z" transform="translate(32.03 36.928)" fill="#dadada" />
                      <path id="Path_38" data-name="Path 38" d="M61.9,68.619l-1.812-1.047,2.241-1.295,1.814,1.047Z" transform="translate(32.982 36.379)" fill="#dadada" />
                      <path id="Path_39" data-name="Path 39" d="M61.82,66.571l2.243-1.295,1.814,1.047-2.243,1.295Z" transform="translate(33.933 35.83)" fill="#dadada" />
                      <path id="Path_40" data-name="Path 40" d="M41.717,80.707,39.9,79.66l2.243-1.295,1.814,1.047Z" transform="translate(21.903 43.014)" fill="#dadada" />
                      <path id="Path_41" data-name="Path 41" d="M43.451,79.707l-1.814-1.047,2.243-1.295,1.814,1.047Z" transform="translate(22.854 42.465)" fill="#dadada" />
                      <path id="Path_42" data-name="Path 42" d="M58.165,73.408,54.1,75.758l-.858.5-.443.256-1.121.646-1.563.9-2.243,1.295L45.184,80.9,43.37,79.856l7.354-4.246.259-.15,5.368-3.1Z" transform="translate(23.806 39.719)" fill="#dadada" />
                      <path id="Path_43" data-name="Path 43" d="M53.85,73.7l-1.814-1.047,2.243-1.295,1.814,1.047Z" transform="translate(28.563 39.169)" fill="#dadada" />
                      <path id="Path_44" data-name="Path 44" d="M55.583,72.7l-1.814-1.047,2.243-1.295,1.814,1.047Z" transform="translate(29.514 38.62)" fill="#dadada" />
                      <path id="Path_45" data-name="Path 45" d="M55.5,70.654l2.243-1.295,1.814,1.047L57.317,71.7Z" transform="translate(30.465 38.071)" fill="#dadada" />
                      <path id="Path_46" data-name="Path 46" d="M59.809,69.413l2.243-1.295,1.814,1.047L61.623,70.46Z" transform="translate(32.829 37.39)" fill="#dadada" />
                      <path id="Path_47" data-name="Path 47" d="M61.543,68.412l2.243-1.295L65.6,68.165l-2.243,1.295Z" transform="translate(33.781 36.841)" fill="#dadada" />
                      <path id="Path_48" data-name="Path 48" d="M63.276,67.411l2.243-1.295,1.814,1.047-2.243,1.295Z" transform="translate(34.732 36.291)" fill="#dadada" />
                      <path id="Path_49" data-name="Path 49" d="M41.36,80.5,43.6,79.206l1.814,1.047-2.243,1.295Z" transform="translate(22.702 43.476)" fill="#dadada" />
                      <path id="Path_50" data-name="Path 50" d="M47.15,79.252l-2.243,1.295L43.093,79.5l2.243-1.295Z" transform="translate(23.654 42.927)" fill="#dadada" />
                      <path id="Path_51" data-name="Path 51" d="M48.883,78.251,46.64,79.546,44.826,78.5,47.069,77.2Z" transform="translate(24.605 42.377)" fill="#dadada" />
                      <path id="Path_52" data-name="Path 52" d="M50.616,77.251l-2.243,1.295L46.559,77.5,48.8,76.2Z" transform="translate(25.556 41.828)" fill="#dadada" />
                      <path id="Path_53" data-name="Path 53" d="M52.349,76.25l-2.243,1.295L48.292,76.5,50.537,75.2Z" transform="translate(26.508 41.279)" fill="#dadada" />
                      <path id="Path_54" data-name="Path 54" d="M54.082,75.249l-2.243,1.295L50.026,75.5,52.268,74.2Z" transform="translate(27.459 40.729)" fill="#dadada" />
                      <path id="Path_55" data-name="Path 55" d="M55.816,74.249l-2.243,1.295L51.759,74.5,54,73.2Z" transform="translate(28.41 40.18)" fill="#dadada" />
                      <path id="Path_56" data-name="Path 56" d="M57.549,73.248l-2.243,1.295L53.492,73.5,55.735,72.2Z" transform="translate(29.362 39.631)" fill="#dadada" />
                      <path id="Path_57" data-name="Path 57" d="M59.282,72.247l-2.243,1.295-1.814-1.047L57.468,71.2Z" transform="translate(30.313 39.081)" fill="#dadada" />
                      <path id="Path_58" data-name="Path 58" d="M61.265,70.254l2.243-1.295,1.814,1.047L63.079,71.3Z" transform="translate(33.628 37.851)" fill="#dadada" />
                      <path id="Path_59" data-name="Path 59" d="M67.055,69,64.812,70.3,63,69.253l2.243-1.295Z" transform="translate(34.579 37.302)" fill="#dadada" />
                      <path id="Path_60" data-name="Path 60" d="M64.732,68.252l2.243-1.295L68.788,68,66.545,69.3Z" transform="translate(35.531 36.752)" fill="#dadada" />
                      <path id="Path_61" data-name="Path 61" d="M42.816,81.341l2.243-1.295,1.814,1.047L44.63,82.389Z" transform="translate(23.501 43.937)" fill="#dadada" />
                      <path id="Path_62" data-name="Path 62" d="M48.605,80.093l-2.243,1.295-1.814-1.047,2.243-1.295Z" transform="translate(24.453 43.388)" fill="#dadada" />
                      <path id="Path_63" data-name="Path 63" d="M46.282,79.34l2.243-1.295,1.814,1.047-2.244,1.295Z" transform="translate(25.404 42.839)" fill="#dadada" />
                      <path id="Path_64" data-name="Path 64" d="M48.016,78.339l2.243-1.295,1.814,1.047-2.243,1.295Z" transform="translate(26.356 42.289)" fill="#dadada" />
                      <path id="Path_65" data-name="Path 65" d="M49.749,77.339l2.243-1.295,1.814,1.047-2.243,1.295Z" transform="translate(27.307 41.74)" fill="#dadada" />
                      <path id="Path_66" data-name="Path 66" d="M51.482,76.338l2.243-1.295,1.814,1.047L53.3,77.385Z" transform="translate(28.258 41.191)" fill="#dadada" />
                      <path id="Path_67" data-name="Path 67" d="M57.272,75.089l-2.243,1.295-1.814-1.047,2.243-1.295Z" transform="translate(29.21 40.641)" fill="#dadada" />
                      <path id="Path_68" data-name="Path 68" d="M54.948,74.336l2.243-1.295L59,74.089l-2.243,1.295Z" transform="translate(30.161 40.092)" fill="#dadada" />
                      <path id="Path_69" data-name="Path 69" d="M61.18,73.844,58.5,75.394l-1.814-1.047,2.684-1.55-2.255-1.3,2.243-1.3,2.255,1.3.736.424,1.078.623-1.5.866Z" transform="translate(31.112 38.532)" fill="#dadada" />
                      <path id="Path_70" data-name="Path 70" d="M64.534,72.141l-1.812-1.047L64.965,69.8l1.812,1.047Z" transform="translate(34.428 38.312)" fill="#dadada" />
                      <path id="Path_71" data-name="Path 71" d="M66.7,68.8l1.814,1.047L66.268,71.14l-1.814-1.047Z" transform="translate(35.379 37.763)" fill="#dadada" />
                      <path id="Path_72" data-name="Path 72" d="M66.187,69.093,68.43,67.8l1.814,1.047L68,70.14Z" transform="translate(36.33 37.214)" fill="#dadada" />
                      <path id="Path_73" data-name="Path 73" d="M44.272,82.182l2.243-1.295,1.814,1.047-2.243,1.295Z" transform="translate(24.301 44.399)" fill="#dadada" />
                      <path id="Path_74" data-name="Path 74" d="M47.818,82.228,46,81.181l2.243-1.295,1.814,1.047Z" transform="translate(25.252 43.849)" fill="#dadada" />
                      <path id="Path_75" data-name="Path 75" d="M47.738,80.181l2.243-1.295,1.812,1.047-2.241,1.295Z" transform="translate(26.203 43.3)" fill="#dadada" />
                      <path id="Path_76" data-name="Path 76" d="M51.714,77.885l1.814,1.047-2.243,1.295L49.471,79.18Z" transform="translate(27.155 42.751)" fill="#dadada" />
                      <path id="Path_77" data-name="Path 77" d="M51.2,78.179l2.243-1.295,1.812,1.047-2.241,1.295Z" transform="translate(28.106 42.201)" fill="#dadada" />
                      <path id="Path_78" data-name="Path 78" d="M52.938,77.178l2.243-1.295,1.814,1.047-2.243,1.295Z" transform="translate(29.057 41.652)" fill="#dadada" />
                      <path id="Path_79" data-name="Path 79" d="M56.485,77.225l-1.814-1.047,2.243-1.295,1.814,1.047Z" transform="translate(30.009 41.103)" fill="#dadada" />
                      <path id="Path_80" data-name="Path 80" d="M56.4,75.177l2.243-1.295,1.814,1.047-2.243,1.295Z" transform="translate(30.96 40.554)" fill="#dadada" />
                      <path id="Path_81" data-name="Path 81" d="M60.822,73.176l.818-.472,1.425-.822,1.814,1.047-1.425.822-.818.472-2.684,1.55-1.814-1.047Z" transform="translate(31.912 39.455)" fill="#dadada" />
                      <path id="Path_82" data-name="Path 82" d="M69.689,72.528l-2.243,1.295-1.814-1.047,2.243-1.295Z" transform="translate(36.026 39.235)" fill="#dadada" />
                      <path id="Path_83" data-name="Path 83" d="M69.609,70.48l1.814,1.047L69.18,72.822l-1.814-1.047Z" transform="translate(36.977 38.686)" fill="#dadada" />
                      <path id="Path_84" data-name="Path 84" d="M71.342,69.479l1.814,1.047-2.243,1.295L69.1,70.774Z" transform="translate(37.928 38.137)" fill="#dadada" />
                      <path id="Path_85" data-name="Path 85" d="M43.292,72.3,48.673,75.4a.981.981,0,0,0,.886,0l8.118-4.687a.27.27,0,0,0,0-.511L52.3,67.1a.981.981,0,0,0-.886,0l-8.118,4.687a.27.27,0,0,0,0,.511" transform="translate(23.662 36.772)" fill="#878787" />
                      <path id="Path_86" data-name="Path 86" d="M30.844,67.389l5,.866,5.822-.012L48.1,70.109l4.622,1.93c1.262.466,1.264,1.135.672,1.471l4.489,2,3.686,2.133c1.207.9.273,2.207-1.269,1.609l-7.5-3-1.4.2,3.753,2.467L60.306,82.6c1.543,1.2.307,2.61-1.267,1.877l-7.3-3.933,5.031,4.941c1.377,1.637-.094,2.577-1.468,1.677l-6.772-5.406-1.808-.4L50.41,86.3c1.309,1.637-.33,2.142-1.636.739L43.841,82.3a29.357,29.357,0,0,1-6.766-2.929,16.729,16.729,0,0,1-4.227-4.475l-5.694-.805c-.214-3.666-.5-6.629,3.689-6.7" transform="translate(14.848 36.99)" fill="#ffaa6d" />
                      <path id="Path_87" data-name="Path 87" d="M63.384,55.919a22.9,22.9,0,0,1,5.729,5.753l7.023,7.337c.96,1.292-.259,2.077-1.434,1.151l-2.3-2.144,3.234,4.535c.98,1.442-.336,2.444-1.657,1.448l-6.171-6.316,4.072,7.76c.835,1.708-.874,2.444-1.952.982l-5.342-7.562-.929-.046,1.826,8.107c.493,1.561-1.411,2.153-2.051.3l-3.587-8.884-2.153-1.753L55.876,70.2l-.367,3.471a2.174,2.174,0,0,1-1.07,1.956.9.9,0,0,1-1.222-.437l.237-2.932L53.2,67.521l2.652-8.948L54.2,55.7l5.245-2.872Z" transform="translate(29.2 28.995)" fill="#ffaa6d" />
                      <path id="Path_88" data-name="Path 88" d="M77.642,84.1l8.781-32.087a4.574,4.574,0,0,1,1.571,4.055L80.385,83.9c-.324,1.176-1.543,1.272-2.721.217Z" transform="translate(42.617 28.55)" fill="#4a4a49" />
                      <path id="Path_89" data-name="Path 89" d="M59.344,75.558l-6.665,32.725L93.46,84.739l6.665-32.725Z" transform="translate(28.916 28.55)" fill="#4a4a49" />
                      <path id="Path_90" data-name="Path 90" d="M51.295,99.312l8.781-32.087a4.574,4.574,0,0,1,1.571,4.055L54.038,99.114c-.324,1.176-1.543,1.272-2.721.217Z" transform="translate(28.156 36.9)" fill="#333332" />
                      <path id="Path_91" data-name="Path 91" d="M68.092,72.484c-.229,1.386.632,1.947,1.921,1.255a5.957,5.957,0,0,0,2.749-3.764c.228-1.385-.632-1.947-1.921-1.253a5.954,5.954,0,0,0-2.749,3.762" transform="translate(37.355 37.558)" fill="#dadada" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_28362" data-name="Group 28362" transform="translate(0 297)">
              <line id="Line_230" data-name="Line 230" x2={258} transform="translate(856.917 5444.329)" fill="none" stroke="#707070" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              <line id="Line_240" data-name="Line 240" x2={311} transform="translate(803.917 5308.329)" fill="none" stroke="#707070" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              <line id="Line_241" data-name="Line 241" x2={366} transform="translate(777.917 5142.329)" fill="none" stroke="#707070" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              <line id="Line_239" data-name="Line 239" x2={244} transform="translate(866.917 5610.329)" fill="none" stroke="#707070" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
            </g>
            <g id="Line">
              <line id="Line_204" data-name="Line 204" y2={199} transform="translate(540.793 5210.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_205" data-name="Line 205" y2="90.829" transform="translate(646.793 5482.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_206" data-name="Line 206" x1="56.583" y1="0.171" transform="translate(750.917 5605.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_207" data-name="Line 207" y2="105.171" transform="translate(807.5 5605.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_238" data-name="Line 238" y2="137.829" transform="translate(717.5 5737.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_220" data-name="Line 220" y1="99.171" transform="translate(1338.5 5320.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_221" data-name="Line 221" y1="145.171" transform="translate(1265.5 5482.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_222" data-name="Line 222" y1="240.171" transform="translate(1265.5 5635.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
            </g>
            <g id="Dots">
              <circle id="Ellipse_134" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(532 5203)" fill="#4b9aff" />
              <circle id="Ellipse_1377" data-name="Ellipse 1377" cx="7.5" cy="7.5" r="7.5" transform="translate(532 5288)" fill="#4b9aff" />
              <circle id="Ellipse_1369" data-name="Ellipse 1369" cx="7.5" cy="7.5" r="7.5" transform="translate(738 5597)" fill="#4b9aff" />
              <circle id="Ellipse_1380" data-name="Ellipse 1380" cx="7.5" cy="7.5" r="7.5" transform="translate(710 5771)" fill="#4b9aff" />
              <circle id="Ellipse_1379" data-name="Ellipse 1379" cx="7.5" cy="7.5" r="7.5" transform="translate(639 5475.595)" fill="#4b9aff" />
              <circle id="Ellipse_1373" data-name="Ellipse 1373" cx="7.5" cy="7.5" r="7.5" transform="translate(1331 5319.095)" fill="#ff6464" />
              <circle id="Ellipse_1374" data-name="Ellipse 1374" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5483.095)" fill="#ff6464" />
              <circle id="Ellipse_1375" data-name="Ellipse 1375" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5638.095)" fill="#ff6464" />
              <circle id="Ellipse_1378" data-name="Ellipse 1378" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5773.095)" fill="#ff6464" />
            </g>
            <g id="Text">
              <g id="Admin-2" data-name="Admin" transform="translate(-221.776 -6496.716)">
                <rect id="Rectangle_298" data-name="Rectangle 298" width={139} height={43} rx="21.5" transform="translate(1487.776 11772.812)" />
                <text id="Admin-3" data-name="Admin" transform="translate(1557.776 11798.812)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-22.561" y={0}>Admin</tspan></text>
              </g>
              <g id="Register" transform="translate(-346 967.838)">
                <g id="User-2" data-name="User" transform="translate(-380.685 -7537.5)">
                  <rect id="Rectangle_298-2" data-name="Rectangle 298" width={141} height={43} rx="21.5" transform="translate(1199 11727)" />
                  <text id="User-3" data-name="User" transform="translate(1270 11753)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-15.414" y={0}>User</tspan></text>
                </g>
              </g>
              <text id="Create_Wallet" data-name="Create Wallet" transform="translate(555.815 5301.67)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>Create </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Wallet</tspan></text>
              <g id="_2fa" data-name="2fa" transform="translate(26 -93)">
                <g id="Rectangle_45" data-name="Rectangle 45" transform="translate(401.417 5503.829)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="352.389" height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width="350.389" height={60} rx={24} fill="none" />
                </g>
                <text id="Store_Send_Receive_Assets" data-name="Store / Send / Receive Assets" transform="translate(577.417 5539.829)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x="-117.552" y={0}>Store / Send / Receive </tspan><tspan y={0} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}>Assets</tspan></text>
              </g>
              <g id="_2fa-2" data-name="2fa">
                <g id="Rectangle_8830" data-name="Rectangle 8830" transform="translate(1132.417 5410.829)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="260.389" height={70} rx={25} stroke="none" />
                  <rect x={1} y={1} width="258.389" height={68} rx={24} fill="none" />
                </g>
                <text id="View_Transactions" data-name="View Transactions" transform="translate(1262.417 5450.829)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x="-72.92" y={0}>View Transactions</tspan></text>
              </g>
              <g id="KYC" transform="translate(-96 4773.17)">
                <g id="Rectangle_45-2" data-name="Rectangle 45" transform="translate(571.417 797)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Swap_your_Token_Instantly" data-name="Swap your Token Instantly" transform="translate(698.417 833)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-85.722" y={0}>Swap your Token </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Instantly</tspan></text>
              </g>
              <g id="User_Dashboard" data-name="User Dashboard" transform="translate(32.194 5007.194)">
                <g id="Rectangle_45-3" data-name="Rectangle 45" transform="translate(576.805 865.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                </g>
                <text id="Stake_their_Preferred_Token" data-name="Stake their Preferred Token" transform="translate(704.805 901.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-89.698" y={0}>Stake their </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Preferred Token</tspan></text>
              </g>
              <g id="My_Profile" data-name="My Profile" transform="translate(32.194 4925.194)">
                <g id="Rectangle_45-4" data-name="Rectangle 45" transform="translate(552.223 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={293} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={291} height={60} rx={24} fill="none" />
                </g>
                <text id="Lend_and_Borrow_Crypto_Assets" data-name="Lend and Borrow Crypto Assets" transform="translate(698.223 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-103.586" y={0}>Lend and Borrow</tspan><tspan y={0} xmlSpace="preserve" fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}> Crypto Assets</tspan></text>
              </g>
              <g id="User_Balance" data-name="User Balance" transform="translate(563.694 4842.38)">
                <g id="Rectangle_45-5" data-name="Rectangle 45" transform="translate(536.5 734.524)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={325} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={323} height={60} rx={24} fill="none" />
                </g>
                <text id="Trade_Pair_Management" data-name="Trade Pair Management" transform="translate(699 770.524)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-80.549" y={0}>Trade Pair Management</tspan></text>
              </g>
              <g id="Website_Content" data-name="Website Content" transform="translate(611 5089.095)">
                <g id="Rectangle_45-6" data-name="Rectangle 45" transform="translate(503.417 784.734)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={301} height={62} rx={20} stroke="none" />
                  <rect x={1} y={1} width={299} height={60} rx={19} fill="none" />
                </g>
                <g id="Group_27450" data-name="Group 27450" transform="translate(-44.537 -9)">
                  <text id="Staking_Management" data-name="Staking Management" transform="translate(698.954 829.734)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-72.821" y={0}>Staking Management</tspan></text>
                </g>
              </g>
              <g id="Website_Content-2" data-name="Website Content" transform="translate(611 4956.095)">
                <g id="Rectangle_45-7" data-name="Rectangle 45" transform="translate(503.417 754.734)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={301} height={62} rx={20} stroke="none" />
                  <rect x={1} y={1} width={299} height={60} rx={19} fill="none" />
                </g>
                <g id="Group_27450-2" data-name="Group 27450" transform="translate(-44.537 -9)">
                  <text id="Check_Lend_or_Borrow_History" data-name="Check Lend or Borrow History" transform="translate(698.954 799.734)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-101.402" y={0}>Check Lend or Borrow History</tspan></text>
                </g>
              </g>
            </g>
          </g>
        </svg>
              )}
          { isMobile && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/defi-wallet-mobile.webp" 
              alt="defi-wallet-workflow"
              width={392} />
              )}
        
      </div>
    </section>
  )
}

export default HowOur