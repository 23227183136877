import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react'


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Core Features</span> of our</span>
              DeFi wallet Development</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Swapping
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Lending
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Staking
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Instant Cash-out
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Chat
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Automated Conversion Rates
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Inclusive Control
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  QR Code Scanner
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Multi-Language Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  NFC Support
                </li>

              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Swapping
                        </h4>
                        <p className="pharagraph">Swap your tokens instantly at the best rates on the decentralized community.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/defiwallet/swapping.png"
                      alt="Swapping"
                      width={325}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Lending </h4>
                        <p className="pharagraph">Our DeFi wallet supports lending where users can lend and borrow crypto assets without any intermediaries through smart contracts.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/defiwallet/lending.png"
                      alt="Lending"
                      width={325}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4"> Staking </h4>
                        <p className="pharagraph">Staking is one of the DeFi earning modules where users can stake their preferred tokens in the respective token pools for a specific time to earn high income.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/defiwallet/staking.png"
                      alt="Staking"
                      width={325}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Instant Cash-out
                        </h4>
                        <p className="pharagraph">Immediately receive fiat by selling your crypto assets. This may help vendors dealing with fiat currencies.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <img width="325px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/instant-cash-out.svg" alt="Instant Cash-out" />
                      
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Chat</h4>
                        <p className="pharagraph">Users can chat between themselves and send/receive cryptos easily. Also, users can request payment from their contacts.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="325px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/chatting.svg" alt="Chat" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Automated Conversion Rates</h4>
                        <p className="pharagraph">With automatic conversion rates in our DeFi wallet, your users can keep track of their balance in native fiat currencies.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/defiwallet/automated-conversion-rates.png"
                      alt="Automated Conversion Rates"
                      width={325}
                    />
                      
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Inclusive Control </h4>
                        <p className="pharagraph">With our DeFi wallet development, users will have complete control over their assets without the need for KYC verification processes. Just connecting a Web3 wallet enables you to use the wallet.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <img width="325px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/no-kyc.svg" alt="Instant Cash-out" />
                    
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">QR Code Scanner</h4>
                        <p className="pharagraph">Users can simply scan the QR code and transfer cryptos which eliminates the human error of sharing the address.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/defiwallet/qr-code-scanner.png"
                      alt="QR Code Scanner"
                      width={325}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multi-Language Support</h4>
                        <p className="pharagraph">We provide multiple language support in our DeFi wallet which helps in upsurging the user count as well as grabbing the attention of many users from various countries.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/defiwallet/multi-language-support.png"
                      alt="Multi Language"
                      width={300}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">NFC Support </h4>
                        <p className="pharagraph">Our DeFi wallet supports the NFC (Near Field Communication) tag using which users can access wallet addresses and transfer crypto assets swiftly.</p>
                         </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/nfc-support.svg" alt="NFC Support " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures