import React from 'react'


const WhyChoose = () => {


  return (
    <section className="choosedesire ychoose otc">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Why Choose </span>us for</span>
              DeFi wallet Development Company?</h3>
          </div>
        </div>
        <div className="d-lg-flex firstblck mb-3">
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/ongoing-support.svg" alt="Ongoing support" />
                </div>
                <h3>
                Ongoing Support
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                When you hire us as your preferred DeFi wallet development service provider, we provide you full ongoing support (based on your decision), for further extensions, upgradation, and integration. Every aspect of DeFi wallet development services is promptly done.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/vast-experience.svg" alt="Vast experience" />
                </div>
                <h3>
                  Vast Experience
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                Our blockchain development programming team has vast experience in developing and implementing world-class blockchain solutions including DeFi wallet integration.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck mb-3">
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/customized-solutions.svg" alt="Customized solutions" />
                </div>
                <h3>
                  Customized Solutions
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                As a DeFi wallet development service provider, we offer white-label solutions to enable you to completely own the script and uniquely customize it as per your business needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/offsite-development.svg" alt="Offsite development" />
                </div>
                <h3>
                  Offsite Development
                </h3>
              </div>
              <div className="content-rght">
               
                <p className="pharagraph">
                Our team can build your DeFi wallet remotely, so you won't need to use your own resources or office space.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/Transparency.svg" alt="Transparency" />
                </div>
                <h3>
                  Transparency
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                You'll always know what's going on when you work with us. We keep everything clear and open, providing updates at every stage so there are no surprises.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiwallet/full-branding.svg" alt="Full branding" />
                </div>
                <h3>
                  Full Branding
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                The DeFi wallet that we develop for you will be completely branded according to your business entity. It will fully represent you and your brand.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default WhyChoose